<template>
  <div>
    <b-sidebar
      id="sidebar-right-incomplete-crawl"
      ref="sidebar_info_license"
      :visible="showOpenInfoLicense"
      bg-variant="white"
      style="z-index: 999999;"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-info-license', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-50">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <div
          class="b-sidebar-body mt-0"
          style="padding: 10px 15px;"
        >
          <h3>License</h3>
        </div>

        <b-card
          no-body
          class="overflow-hidden w-100 container-all-cards"
          :style="idsite.planType || (idsite.plan !== 'Free' && idsite.plan !== 'free') ? (annualyBill ? 'height: 700px;' : 'height: 700px;') : (annualyBill ? 'height: 730px;' : 'height: 730px;')"
        >
          <div class="d-flex ml-1" v-if="(idsite.plan === 'Free' || idsite.plan === 'free')">
            <span :style="annualyBill ? 'color: #232C51; font-size: 16px;' : 'color: #232C51; font-weight: bold; font-size: 16px;'" class="mr-1">Bill Monthly</span>
            <b-form-checkbox
              v-model="annualyBill"
              :checked="annualyBill"
              name="check-button"
              switch
              inline
              :disabled="processSubmit"
            >
              <span :style="annualyBill ? 'color: #232C51; font-weight: bold; font-size: 16px;' : 'color: #232C51; font-size: 16px;'" class="ml-50">Bill Annualy <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151);">( up to 25% off )</span></span>
            </b-form-checkbox>
          </div>
          <div class="d-flex ml-1" v-else-if="idsite.freeTrial === true && idsite.subscriptionId">
            <span :style="annualyBill ? 'color: #232C51; font-size: 16px;' : 'color: #232C51; font-weight: bold; font-size: 16px;'" class="mr-1">Bill Monthly</span>
            <b-form-checkbox
              v-model="annualyBill"
              :checked="annualyBill"
              name="check-button"
              switch
              inline
              :disabled="processSubmit"
            >
              <span :style="annualyBill ? 'color: #232C51; font-weight: bold; font-size: 16px;' : 'color: #232C51; font-size: 16px;'" class="ml-50">Bill Annualy <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151);">( up to 25% off )</span></span>
            </b-form-checkbox>
          </div>
          <div class="d-flex ml-1" v-else-if="idsite.paidRecurrency !== 'Anually'">
            <span :style="annualyBill ? 'color: #232C51; font-size: 16px;' : 'color: #232C51; font-weight: bold; font-size: 16px;'" class="mr-1">Bill Monthly</span>
            <b-form-checkbox
              v-model="annualyBill"
              :checked="annualyBill"
              name="check-button"
              switch
              inline
              :disabled="processSubmit"
            >
              <span :style="annualyBill ? 'color: #232C51; font-weight: bold; font-size: 16px;' : 'color: #232C51; font-size: 16px;'" class="ml-50">Bill Annualy <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151);">( up to 25% off )</span></span>
            </b-form-checkbox>
          </div>
          <b-row
            no-gutters
            class="mt-1 row-container-configs ml-0 px-1"
            style="height: 180px"
          >
            <!-- v-if="idsite.planType || (idsite.plan !== 'Free' && idsite.plan !== 'free')" -->
            <b-col
              :md="'12'"
              sm="12"
              :class="`cards card-config-license w-100 type-active mr-50 ${crawlType === '0' || crawlType === 0 ? 'type-active' : 'type-inactive'}`"
              :style="disabled5 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : annualyBills ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="disabled5 ? 'height: 67px; cursor: no-drop;' : annualyBills ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="disabled5 ? true : annualyBills"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="0"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="disabled5 ? true : annualyBills"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">$0<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">Free Plan <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(0)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="text-card-desc-license mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">2 pages</span>
                  </p>

                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">Downgrade</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              :md="'12'"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '5' || crawlType === 5 ? 'type-active' : 'type-inactive'}`"
              :style="disabled100 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(5)) ? 'height: 67px;' : annualyBill && year5 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="disabled100 ? 'height: 67px; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(5)) ? 'height: 67px;' : annualyBill && year5 ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="disabled100 ? true : (annualyBill && isAbleToDowngrade(5)) ? false : annualyBill && year5"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="5"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="disabled100 ? true : (annualyBill && isAbleToDowngrade(5)) ? false : annualyBill && year5"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(5) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / day*' : ' / day'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">5 pages <span style="color: rgb(120, 56, 255) !important;">- TEST (Daily)</span> <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(5)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="text-card-desc-license mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    TEST MODE - SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">5 pages</span>
                  </p>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{!idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="!idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              :md="'12'"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '6' || crawlType === 6 ? 'type-active' : 'type-inactive'}`"
              :style="disabled200 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(6)) ? 'height: 67px;' : annualyBill && year5 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="disabled200 ? 'height: 67px; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(6)) ? 'height: 67px;' : annualyBill && year5 ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="disabled200 ? true : (annualyBill && isAbleToDowngrade(6)) ? false : annualyBill && year5"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="6"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="disabled200 ? true : (annualyBill && isAbleToDowngrade(6)) ? false : annualyBill && year5"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(6) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / day*' : ' / day'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">200 pages <span style="color: rgb(120, 56, 255) !important;">- TEST (Daily)</span> <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(200)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="text-card-desc-license mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    TEST MODE - SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">200 pages</span>
                  </p>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{!idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="!idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              :md="'12'"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '1' || crawlType === 1 ? 'type-active' : 'type-inactive'}`"
              :style="disabled5 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(1)) ? 'height: 67px;' : annualyBill && year5 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="disabled5 ? 'height: 67px; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(1)) ? 'height: 67px;' : annualyBill && year5 ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="disabled5 ? true : (annualyBill && isAbleToDowngrade(1)) ? false : annualyBill && year5"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="1"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="disabled5 ? true : (annualyBill && isAbleToDowngrade(1)) ? false : annualyBill && year5"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(1) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">5 pages <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(5)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="text-card-desc-license mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    Unlimited fixes - SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">5 pages</span>
                  </p>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{!idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="!idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              md="12"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '2' || crawlType === 2 ? 'type-active' : 'type-inactive'}`"
              :style="disabled10 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(2)) ? 'height: 67px;' : annualyBill && year10 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="disabled10 ? 'height: 67px; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(2)) ? 'height: 67px;' : annualyBill && year10 ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="disabled10 ? true : (annualyBill && isAbleToDowngrade(2)) ? false : annualyBill && year10"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="2"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="disabled10 ? true : (annualyBill && isAbleToDowngrade(2)) ? false : annualyBill && year10"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(2) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">10 pages <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(10)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="text-card-desc-license mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    Unlimited fixes - SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">10 pages</span>
                  </p>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              md="12"
              sm="12"
              :class="`mt-1 cards card-config-license w-100 ml-0 ${crawlType === '3' || crawlType === 3 ? 'type-active' : 'type-inactive'}`"
              :style="disabled25 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(3)) ? 'height: 67px;' : annualyBill && year25 ? 'height: 67px; background: #f0f0f0; cursor: no-drop;' : 'height: 67px;'"
            >
              <label
                class="w-100 custom-radio-plan-container"
                :style="disabled25 ? 'height: 67px; cursor: no-drop;' : (annualyBill && isAbleToDowngrade(3)) ? 'height: 67px;' : annualyBill && year25 ? 'height: 67px; cursor: no-drop;' : 'height: 67px;'"
                role="button"
                :disabled="disabled25 ? true : (annualyBill && isAbleToDowngrade(3)) ? false : annualyBill && year25"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="3"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                  :disabled="disabled25 ? true : (annualyBill && isAbleToDowngrade(3)) ? false : annualyBill && year25"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(3) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">25 pages <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(25)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="text-card-desc-license mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    Unlimited fixes - SEOcloud Copilot for <span style="font-weight: 900; font-size: 14px;">25 pages</span>
                  </p>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{idsite.planType === '10' || idsite.planType === 10 || idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                      
                    >
                      <feather-icon :icon="idsite.planType === '10' || idsite.planType === 10 || idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <b-col
              md="12"
              sm="12"
              :class="`mt-1 cards card-config-license2 w-100 ml-0 ${crawlType === '4' || crawlType === 4 ? 'type-active' : 'type-inactive'}`"
              style="height: 135px;"
            >
              <label
                class="w-100 custom-radio-plan-container2"
                style="height: 135px;"
                role="button"
              >
                <input
                  v-model="crawlType"
                  type="radio"
                  value="4"
                  checked
                  class="custom-radio-plan"
                  style="float: left; margin-top: 10px; margin-left: 10px;"
                >
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-right: 15px; float: right;">{{ getPlanAmount(4) }}<span style="color: #656B85; font-weight: normal; font-size: 13px;">{{annualyBill ? ' / month*' : ' / month'}}</span></span>
                <span style="padding-top: 6px; font-size: 16px; color: #181E38; height: 20px; display: block; font-weight: bold; padding-left: 35px;">More <span style="font-size: 13px; font-weight: 400; color: rgb(151, 151, 151); padding-left: 6px;" v-if="isActivePlan(customSlider ? parseInt(customSlider, 10) : 50)">( Current )</span></span>
                <div
                  class="pb-50"
                  style="margin-top: 3px;"
                >
                  <p class="text-card-desc-license mb-0 mt-1" style="margin-left: 35px; color: #656B85;">
                    <feather-icon
                      class=""
                      icon="CheckIcon"
                      size="16"
                      style="color: #A7AAB9; padding-bottom: 4px;"
                    />
                    Unlimited fixes - SEOcloud for more pages
                  </p>
                  <div class="d-flex mt-50 desc-license-custom" style="text-align: center; align-content: center; margin-left: 37%;">
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      :style="customSlider === 50 ? 'background: #CFD3E5; padding: 0px; cursor: no-drop; height: 20px; width: 20px;' : 'background: rgb(120, 56, 255); padding: 0px; height: 20px; width: 20px;'"
                      @click.prevent="customSlider === 50 ? null : customSlider -= 50"
                    >
                      <feather-icon icon="ChevronLeftIcon" style="color: #fff;" />
                    </b-button>
                    <span style="position: relative; top: 1px; margin-right: 5px; margin-left: 5px;"><span style="font-weight: 900; font-size: 14px;">{{customSlider}} pages</span> license</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      :style="customSlider === 1000 ? 'background: #CFD3E5; padding: 0px; cursor: no-drop; height: 20px; width: 20px;' : 'background: rgb(120, 56, 255); padding: 0px; height: 20px; width: 20px;'"
                      @click.prevent="customSlider === 1000 ? null : customSlider += 50"
                    >
                      <feather-icon icon="ChevronRightIcon" style="color: #fff;" />
                    </b-button>
                  </div>
                  <div class="d-flex" style="margin-left: 15px;">
                    <span style="margin-right: 15px;" class="pt-50">50</span>
                    <vue-slider
                      v-model="customSlider"
                      :direction="direction"
                      class="mb-2 mt-50 slider-custom-plan"
                      style="width: 85%;"
                      :marks="false"
                      :adsorb="true"
                      :interval="50"
                      :min="50"
                      :max="1000"
                    />
                    <span style="margin-left: 15px; margin-right: 7px;" class="pt-50">1000</span>
                  </div>
                  <span style="color: #CA34FF; position: relative; right: 0px; float: right; top: -20px; margin-right: 13px;">
                    <span style="position: relative; top: 2px; margin-right: 5px;">{{idsite.planType === '25' || idsite.planType === 25 || idsite.planType === '10' || idsite.planType === 10 || idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'Upgrade' : 'Downgrade'}}</span>
                    <b-button
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      size="sm"
                      style="background: #CA34FF40; padding: 0px;"
                    >
                      <feather-icon :icon="idsite.planType === '25' || idsite.planType === 25 || idsite.planType === '10' || idsite.planType === 10 || idsite.planType === '5' || idsite.planType === 5 || !idsite.planType ? 'ChevronUpIcon' : 'ChevronDownIcon'" style="color: #CA34FF;" />
                    </b-button>
                  </span>
                </div>
              </label>
            </b-col>
            <p v-if="annualyBill" style="color: #7838ff; text-align: right; margin-right: 30px; width: 100%; margin-top: 10px;">* billed anually</p>
          </b-row>
        </b-card>
        <p v-if="idsite.paidRecurrency === 'Monthly' && idsite.subscriptionId && annualyBill && !isAbleToDowngrade(crawlType)" :style="idsite.freeTrial === true && idsite.subscriptionId ? 'color: #fd9714; margin-bottom: 0; position: relative; top: -16px;' : 'color: #fd9714; margin-bottom: 0; position: relative; top: -22px;'" class="ml-2 float-left">{{idsite.freeTrial ? '' : `Your license will downgrade to ${crawlType === '0' || crawlType === 0 ? 2 : crawlType === '1' || crawlType === 1 ? 5 : crawlType === '2' || crawlType === 2 ? 10 : crawlType === '3' || crawlType === 3 ? 25 : customSlider} pages on ${idsite.nextDueCancel}`}}</p>
        <p v-else-if="(idsite.nextDueCancel && idsite.nextDueCancel !== '-') && ((idsite.planType > getSelectedActualPlanType()) || (annualyBill && isAbleToDowngrade(crawlType) && idsite.paidRecurrency === 'Anually' && idsite.planType > getSelectedActualPlanType()))" :style="idsite.freeTrial === true && idsite.subscriptionId ? 'color: #fd9714; margin-bottom: 0; position: relative; top: -16px;' : 'color: #fd9714; margin-bottom: 0; position: relative; top: -22px;'" class="ml-2 float-left">{{idsite.freeTrial ? '' : idsite.paidRecurrency === 'Monthly' && idsite.subscriptionId && annualyBill && !isAbleToDowngrade(crawlType) || idsite.paidRecurrency === 'Monthly' && idsite.subscriptionId && !annualyBill ? `Your license will downgrade to ${crawlType === '0' || crawlType === 0 ? 2 : crawlType === '1' || crawlType === 1 ? 5 : crawlType === '2' || crawlType === 2 ? 10 : crawlType === '3' || crawlType === 3 ? 25 : customSlider} pages on ${idsite.nextDueCancel}` : (annualyBill && isAbleToDowngrade(crawlType) && idsite.paidRecurrency === 'Anually' && idsite.planType > getSelectedActualPlanType()) ? `Your license will downgrade to ${crawlType === '0' || crawlType === 0 ? 2 : crawlType === '1' || crawlType === 1 ? 5 : crawlType === '2' || crawlType === 2 ? 10 : crawlType === '3' || crawlType === 3 ? 25 : customSlider} pages on ${idsite.nextDueCancel}` : ''}}</p>
        <div class="w-100" style="display: block; height: 20px;" v-if="idsite.freeTrial === true && idsite.subscriptionId">
          <b-badge class="mt-0 cursor-pointer float-right" :style="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0) ? 'font-size: 11px; position: relative; top: -15px; right: 120px;' : 'font-size: 11px; position: relative; top: -15px; right: 94px;'" variant="light-dark" v-if="idsite.freeTrial === true && idsite.subscriptionId">
            <span style="color: #646464 !important">On trial</span>
          </b-badge>
        </div>

        <!-- <p v-if="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0)" style="color: rgb(162, 162, 162);" class="ml-2">You'll keep your free license</p> -->
        <!-- <p v-if="(idsite.nextDueCancel && idsite.nextDueCancel !== '-') && ((idsite.planType > getSelectedActualPlanType()) || (annualyBill && isAbleToDowngrade(crawlType) && idsite.paidRecurrency === 'Anually'))" style="color: #fd9714;" class="ml-2">Your license will downgrade to {{crawlType === '0' || crawlType === 0 ? 2 : crawlType === '1' || crawlType === 1 ? 5 : crawlType === '2' || crawlType === 2 ? 10 : crawlType === '3' || crawlType === 3 ? 25 : customSlider}} pages on {{idsite.nextDueCancel}}</p> -->
        <b-button
          :style="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0) ? 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 208px; height: 46px; border-radius: 15px; margin-right: 40px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;'"
          class="float-right"
          variant="primary"
          @click.prevent="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0) ? submitCancelation() : submitEditar()"
          :disabled="isActivePlanSelected() ? true : processSubmit ? true : (annualyBill && isAbleToDowngrade(crawlType)) ? false : false"
        >
          <span
            class=""
            style="font-weight: 700; color: #FFFFFF;"
          >{{idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0) ? 'Cancel current license' : 'Update license'}}</span>
        </b-button>
        <span style="color: rgb(162, 162, 162); width: 100%; text-align: right; margin-right: 54px; margin-top: 5px;" class="float-right" v-if="idsite.customerId && idsite.subscriptionId && (crawlType === '0' || crawlType === 0)">You'll keep your free license</span>
        <span style="color: rgb(94, 94, 94); width: 100%; text-align: right; margin-right: 66px; margin-top: 5px;" class="float-right" v-else-if="!idsite.customerId || !idsite.subscriptionId && !iATrial">5 days free trial</span>

        <b-modal
          :title="showUpdateCC ? 'Add Credit card information' : showOpenCheckout ? 'Checkout' : 'Update license'"
          v-model="showUpdatePlan"
          hide-footer
          no-close-on-backdrop
          hide-backdrop
          :style="{ zIndex: 9999999 }"
          id="mi-modal"
          content-class="modal-custom-info-license"
          class="modal-custom-info-license1"
        >
          <div v-if="!showUpdateCC && !showOpenCheckout">
            <p style="color: #232c51; font-size: 16px;" class="pb-0">Select payment method: <span style="color: rgb(168, 168, 168)">{{planSelectedPayment}}</span></p>
            
            <b-form style="margin-top: 20px;">
              <b-form-group
                label="Select payment method:"
                label-for="vue-select"
              >
                <v-select
                  id="vue-select"
                  v-model="selectedPaymentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionPaymentMethod"
                  :clearable="false"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="float-right ml-1 update-plan-confirm"
                variant="primary"
                @click="upgradePlan"
                :disabled="processSubmitUpgrade || !selectedPaymentMethod"
              >
                View payment details
              </b-button>
              <!-- redirectToBilling(idsite.customerId) -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="float-right"
                variant="outline-secondary"
                @click="showUpdateCC = true"
                :disabled="processSubmitUpgrade"
              >
                Add card
              </b-button>
            </b-form>
          </div>
          <div v-else-if="showUpdateCC && !showOpenCheckout">
            <feather-icon
              class="cursor-pointer"
              icon="ArrowLeftIcon"
              size="20"
              style="margin-bottom: 20px;"
              @click="showUpdateCC = false, showOpenCheckout = false"
            />

            <form @submit.prevent="handleSubmitCC">
              <div id="card-element"></div>
              <button
                class="mt-2"
                type="submit"
                :style="processSubmitUpgrade ? 'opacity: 0.5; cursor: no-drop; border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;' : 'border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;'"
                :disabled="processSubmitUpgrade"
              >Add Payment Method</button>
            </form>
          </div>
          <div v-else-if="!showUpdateCC && showOpenCheckout">
            <feather-icon
              class="cursor-pointer"
              icon="ArrowLeftIcon"
              size="20"
              style="margin-bottom: 20px;"
              @click="showUpdateCC = false, showOpenCheckout = false"
            />

              <div>
                <p>Upgrade price is calculated from a credit of the unused portion of the current plan and billing of the new plan for the same period.</p>
                <p><strong>Subtotal: </strong>{{ amountCheckout }}</p>
              </div>
              <button
                class="mt-2"
                type="submit"
                :style="processSubmitUpgrade ? 'opacity: 0.5; cursor: no-drop; border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;' : 'border: 1px solid #7838FF !important; background: #7838ff !important; border-radius: 13px; padding: 0.786rem 1.5rem; color: #fff !important; float: right !important; font-size: 1rem;'"
                @click.prevent="upgradePlanConfirmation"
                :disabled="processSubmitUpgrade"
              >Checkout</button>
          </div>
        </b-modal>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BBadge,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import VueSlider from 'vue-slider-component'
import storeInd from '@/store/index'
import 'vue-slider-component/theme/default.css'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import { loadStripe } from '@stripe/stripe-js'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth, dbErrors } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    BBadge,
    VueSlider,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenInfoLicense',
    event: 'update:show-open-info-license',
  },
  props: ['idsite','userData','showOpenInfoLicense'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      dir: 'ltr',
      disabled5: false,
      disabled10: false,
      disabled25: false,
      disabled100: false,
      disabled200: false,
      customSlider: 50,
      annualyBills: false,
      annualyBill: true,
      selectedMove: '',
      processSubmit: false,
      numberPages: 0,
      crawlType: 0,
      sitesImg: require('@/assets/images/illustration/i-site.png'),
      siteJS: require('@/assets/images/icons/js.png'),
      marks: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000],
      showUpdatePlan: false,
      planSelectedPayment: '',
      selectedPaymentMethod: '',
      // selectedPaymentMethodId: '',
      optionPaymentMethod: [],
      processSubmitUpgrade: false,
      ammountActualYear: 0,
      ammountActualMonth: 0,
      iATrial: false,
      arrayLicenses: [],

      // card element
      stripe: null,
      cardElement: null,
      showUpdateCC: false,

      agentesAsignados: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Missing Title',
      optionsErrors: [
        'Meta title',
        'Missing Title',
        'Missing descriptions',
        'Missing alts',
        'Headings H1',
        'Low word count',
        'External links',
      ],
      fixText: '',
      customPlanMonth: 12,
      customPlan: 220,

      infoCheckout: '',
      amountCheckout: '',
      showOpenCheckout: false,

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
      appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),

      // options plans upgrade/drowngrade
      monthFree: false,
      month5: false,
      month10: false,
      month25: false,
      month50: false,
      month100: false,
      month150: false,
      month200: false,
      month250: false,
      month300: false,
      month350: false,
      month400: false,
      month450: false,
      month500: false,
      month550: false,
      month600: false,
      month650: false,
      month700: false,
      month750: false,
      month800: false,
      month850: false,
      month900: false,
      month950: false,
      month1000: false,

      yearFree: false,
      year5: false,
      year10: false,
      year25: false,
      year50: false,
      year100: false,
      year150: false,
      year200: false,
      year250: false,
      year300: false,
      year350: false,
      year400: false,
      year450: false,
      year500: false,
      year550: false,
      year600: false,
      year650: false,
      year700: false,
      year750: false,
      year800: false,
      year850: false,
      year900: false,
      year950: false,
      year1000: false,
    }
  },
  watch: {
    showOpenInfoLicense(val) {
      this.showOpenCheckout = false
      this.monthFree = false
      this.yearFree = false
      this.month5 = false
      this.month10 = false
      this.month25 = false
      this.month50 = false
      this.month100 = false
      this.month150 = false
      this.month200 = false
      this.month250 = false
      this.month300 = false
      this.month350 = false
      this.month400 = false
      this.month450 = false
      this.month500 = false
      this.month550 = false
      this.month600 = false
      this.month650 = false
      this.month700 = false
      this.month750 = false
      this.month800 = false
      this.month850 = false
      this.month900 = false
      this.month950 = false
      this.month1000 = false

      this.year5 = false
      this.year10 = false
      this.year25 = false
      this.year50 = false
      this.year100 = false
      this.year150 = false
      this.year200 = false
      this.year250 = false
      this.year300 = false
      this.year350 = false
      this.year400 = false
      this.year450 = false
      this.year500 = false
      this.year550 = false
      this.year600 = false
      this.year650 = false
      this.year700 = false
      this.year750 = false
      this.year800 = false
      this.year850 = false
      this.year900 = false
      this.year950 = false
      this.year1000 = false

      this.customSlider = 50
      this.customPlanMonth = 12
      this.customPlan = 220
      this.disabled5 = false
      this.disabled10 = false
      this.disabled25 = false
      this.disabled100 = false
      this.disabled200 = false
      // this.annualyBill = true
      this.iATrial = false
      this.processSubmit = false
      this.showUpdatePlan = false
      this.showUpdateCC = false
      this.planSelectedPayment = ''
      this.selectedPaymentMethod = ''
      this.optionPaymentMethod = []
      this.processSubmitUpgrade = false
      this.ammountActualYear = 0
      this.ammountActualMonth = 0
      localStorage.removeItem('jsonInfoLic')
      if(val) {
        dbErrors.collection('stripe').doc('plans').get()
        .then(docR => {
          this.arrayLicenses = docR.data().data
        })

        if(this.idsite.planType) {
          this.getPlanAmountInteger(this.idsite.planType)
        }
        this.annualyBill = true
        // console.log(this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId);
        document.body.style.overflow = 'hidden'

        if(this.idsite) {
          if(this.idsite.totalPros) {
            if(this.idsite.totalPros <= 5) {
              this.crawlType = 1
            } else if(this.idsite.totalPros <= 10) {
              this.crawlType = 2
              this.disabled5 = true
              this.disabled100 = true
            } else if(this.idsite.totalPros <= 25) {
              this.crawlType = 3
              this.disabled5 = true
              this.disabled10 = true
              this.disabled100 = true
            } else {
              this.crawlType = 4
              this.customSlider = 50
              this.disabled5 = true
              this.disabled10 = true
              this.disabled25 = true
              this.disabled100 = true
            }
          } else {
            // console.log(this.idsite)
            if(this.idsite.planSite === 'test') {
              if(this.idsite.planType === '5' || this.idsite.planType === 5) {
                this.crawlType = 5
              } else if(this.idsite.planType === '200' || this.idsite.planType === 200) {
                this.crawlType = 6
              }
            }
            else if(this.idsite.planType) {
              if(this.idsite.planType === '5' || this.idsite.planType === 5) {
                this.crawlType = 1
              } else if(this.idsite.planType === '10' || this.idsite.planType === 10) {
                this.crawlType = 2
              } else if(this.idsite.planType === '25' || this.idsite.planType === 25) {
                this.crawlType = 3
              } else {
                this.crawlType = 4
                this.customSlider = parseInt(this.idsite.planType, 10)
              }
            } else {
              this.crawlType = 3
            }
          }

          db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).get()
          .then(docPS => {
            if(docPS.exists) {
              this.iATrial = docPS.data().iATrial ? docPS.data().iATrial : false
            }
          })

          if(this.idsite.paidRecurrency && this.idsite.paidRecurrency === 'Anually' && this.idsite.subscriptionId) {
            this.annualyBill = true

            // actual amount decimals
            // console.log(this.getSelectedActualPlanType(), this.getActualAmountInteger(this.crawlType))

            var priceId = ''
            if(this.crawlType === 5 || this.crawlType === '5') {
              priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
            } else if(this.crawlType === 6 || this.crawlType === '6') {
              priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
            }
            else if(this.crawlType === 1 || this.crawlType === '1') {
              priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
            } else if(this.crawlType === 2 || this.crawlType === '2') {
              priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
            } else if(this.crawlType === 3 || this.crawlType === '3') {
              priceId = 'price_1PePQVEha01YHD9prbkraO7a'
            } else if(this.crawlType === 4 || this.crawlType === '4') {
              if((this.customPlan + (this.customSlider - 50)) === 220) {
                priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
              } else if((this.customPlan + (this.customSlider - 50)) === 270) {
                priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
              } else if((this.customPlan + (this.customSlider - 50)) === 320) {
                priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
              } else if((this.customPlan + (this.customSlider - 50)) === 370) {
                priceId = 'price_1PsDnrEha01YHD9p871921P2'
              } else if((this.customPlan + (this.customSlider - 50)) === 420) {
                priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
              } else if((this.customPlan + (this.customSlider - 50)) === 470) {
                priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
              } else if((this.customPlan + (this.customSlider - 50)) === 520) {
                priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
              } else if((this.customPlan + (this.customSlider - 50)) === 570) {
                priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
              } else if((this.customPlan + (this.customSlider - 50)) === 620) {
                priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
              } else if((this.customPlan + (this.customSlider - 50)) === 670) {
                priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
              } else if((this.customPlan + (this.customSlider - 50)) === 720) {
                priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
              } else if((this.customPlan + (this.customSlider - 50)) === 770) {
                priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
              } else if((this.customPlan + (this.customSlider - 50)) === 820) {
                priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
              } else if((this.customPlan + (this.customSlider - 50)) === 870) {
                priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
              } else if((this.customPlan + (this.customSlider - 50)) === 920) {
                priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
              } else if((this.customPlan + (this.customSlider - 50)) === 970) {
                priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
              } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
                priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
              } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
                priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
              } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
                priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
              } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
                priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
              }
            }

            this.processSubmit = true

            let configAmountProration = {
              method: 'post',
              maxBodyLength: Infinity,
              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-amount-subscription-annual',
              headers: { 
                'Content-Type': 'application/json'
              },
              data: JSON.stringify({
                priceId,
                subscriptionId: this.idsite.subscriptionId
              })
            };

            this.axios(configAmountProration)
            .then((responseAmountProration) => {
              // console.log(responseAmountProration.data.subscription)
              this.ammountActualYear = responseAmountProration.data.subscription
              this.processSubmit = false
            })
            .catch(e => console.log(e))
          } else if(this.idsite.paidRecurrency && this.idsite.paidRecurrency === 'Monthly' && this.idsite.subscriptionId) {
            let configAmountProration = {
              method: 'post',
              maxBodyLength: Infinity,
              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-amount-subscription-annual',
              headers: { 
                'Content-Type': 'application/json'
              },
              data: JSON.stringify({
                priceId,
                subscriptionId: this.idsite.subscriptionId
              })
            };

            this.axios(configAmountProration)
            .then((responseAmountProration) => {
              // console.log(responseAmountProration.data.subscription)
              this.ammountActualMonth = responseAmountProration.data.subscription
              this.processSubmit = false
            })
            .catch(e => console.log(e))
          }
        }
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    showUpdatePlan(val) {
      if(!val) {
        this.processSubmit = false
        this.showUpdateCC = false
        this.showOpenCheckout = false
        this.processSubmitUpgrade = false
      }
    },
    showUpdateCC(val) {
      if(val) {
        // const elements = this.stripe.elements();
        // this.cardElement = elements.create('card');
        // this.cardElement.mount('#card-element');
        this.loadStripeCard()
        this.showOpenCheckout = false
      } else {
        this.processSubmitUpgrade = false
        this.processSubmit = false
      }
    }
  },
  computed: {
    direction() {
      if(storeInd.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  validations: {
  },
  methods: {
    async loadStripeCard() {
      this.stripe = await loadStripe('pk_test_51PVKa9Eha01YHD9pDFl3O2hsGF5KgINCA5fooZqjlbDqfmmrvWwABRufNHVDdGdl643Wyv3VL0vZuYazlAQrBDeu00sCy4UCuP');
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
        style: {
          base: {
            fontSize: '16px',
            color: '#32325d',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
        },
        hidePostalCode: true
        // placeholder: {
        //   number: '1234 1234 1234 1234',
        //   expiry: 'MM / YY',
        //   cvc: 'CVC',
        //   postal: 'ZIP Code'
        // }
      });
      this.cardElement.mount('#card-element');
    },
    isActivePlan(planSite) {
      if(this.annualyBill) {
        if(this.idsite.paidRecurrency === 'Daily') {
          if(this.idsite.planType === 5 && planSite === 5 && this.idsite.planSite === 'test') {
            return true
          } else if(this.idsite.planType === 200 && planSite === 200 && this.idsite.planSite === 'test') {
            return true
          }
        }

        else if(this.idsite.paidRecurrency === 'Anually') {
          if(this.idsite.planType === 5 && planSite === 5 && this.idsite.planSite === 'test') {
            return true
          } else if(this.idsite.planType === 200 && planSite === 200 && this.idsite.planSite === 'test') {
            return true
          }

          else if((!this.idsite.planType || this.idsite.planType === 0 || this.idsite.planType === '0') && planSite === 0) {
            return true
          } else if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 10 && planSite === 10) {
            return true
          } else if(this.idsite.planType === 25 && planSite === 25) {
            return true
          } else if(this.idsite.planType === planSite) {
            return true
          }
        }
      } else {
        if(this.idsite.paidRecurrency === 'Daily') {
          if(this.idsite.planType === 5 && planSite === 5 && this.idsite.planSite === 'test') {
            return true
          } else if(this.idsite.planType === 200 && planSite === 200 && this.idsite.planSite === 'test') {
            return true
          }
        }

        else if(this.idsite.paidRecurrency === 'Monthly') {
          if(this.idsite.planType === 5 && planSite === 5 && this.idsite.planSite === 'test') {
            return true
          } else if(this.idsite.planType === 200 && planSite === 200 && this.idsite.planSite === 'test') {
            return true
          }

          else if((!this.idsite.planType || this.idsite.planType === 0 || this.idsite.planType === '0') && planSite === 0) {
            return true
          } else if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 10 && planSite === 10) {
            return true
          } else if(this.idsite.planType === 25 && planSite === 25) {
            return true
          } else if(this.idsite.planType === planSite) {
            return true
          }
        }
      }

      return false
    },
    isActivePlanSelected() {
      var planSite = 0
      if(this.crawlType === '5' || this.crawlType === 5) {
        planSite = 5
      } else if(this.crawlType === '6' || this.crawlType === 6) {
        planSite = 200
      }

      else if(this.crawlType === '0' || this.crawlType === 0) {
        planSite = 0
      } else if(this.crawlType === '1' || this.crawlType === 1) {
        planSite = 5
      } else if(this.crawlType === '2' || this.crawlType === 2) {
        planSite = 10
      } else if(this.crawlType === '3' || this.crawlType === 3) {
        planSite = 25
      } else if(this.crawlType === '4' || this.crawlType === 4) {
        planSite = this.customSlider ? parseInt(this.customSlider, 10) : 50
      }
      // console.log(this.idsite.planType,this.crawlType,planSite)

      if(this.annualyBill) {
        if(this.idsite.paidRecurrency === 'Anually') {
          if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 200 && planSite === 200) {
            return true
          }

          else if((!this.idsite.planType || this.idsite.planType === 0 || this.idsite.planType === '0' || this.idsite.plan === 'Free' || this.idsite.plan === 'free') && planSite === 0) {
            return true
          } else if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 10 && planSite === 10) {
            return true
          } else if(this.idsite.planType === 25 && planSite === 25) {
            return true
          } else if(this.idsite.planType === planSite) {
            return true
          }
        }
      } else {
        if(this.idsite.paidRecurrency === 'Monthly') {
          if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 200 && planSite === 200) {
            return true
          }

          else if((!this.idsite.planType || this.idsite.planType === 0 || this.idsite.planType === '0' || this.idsite.plan === 'Free' || this.idsite.plan === 'free') && planSite === 0) {
            return true
          } else if(this.idsite.planType === 5 && planSite === 5) {
            return true
          } else if(this.idsite.planType === 10 && planSite === 10) {
            return true
          } else if(this.idsite.planType === 25 && planSite === 25) {
            return true
          } else if(this.idsite.planType === planSite) {
            return true
          }
        }
      }

      if(this.idsite.plan === 'Free' || this.idsite.plan === 'free') {
        if(planSite === 0) {
          return true
        }
      }

      return false
    },
    getSelectedActualPlanType() {
      if(this.crawlType === '5' || this.crawlType === 5) {
        return 5
      } else if(this.crawlType === '6' || this.crawlType === 6) {
        return 200
      }

      else if(this.crawlType === '0' || this.crawlType === 0) {
        return 0
      } else if(this.crawlType === '1' || this.crawlType === 1) {
        return 5
      } else if(this.crawlType === '2' || this.crawlType === 2) {
        return 10
      } else if(this.crawlType === '3' || this.crawlType === 3) {
        return 25
      } else {
        return parseInt(this.customSlider, 10)
      }
    },
    getPlanAmount(typeCrawl) {
      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }

        else if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = 'price_1PePQVEha01YHD9prbkraO7a'
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = 'price_1PsDnrEha01YHD9p871921P2'
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
          }
        }
      } else {
        // monthly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }
        
        else if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = 'price_1PsYAIEha01YHD9pFMqNcy4D'
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = 'price_1PsY9MEha01YHD9pdo4NDKoI'
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = 'price_1PsYAmEha01YHD9pstp3srF0'
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = 'price_1PsChZEha01YHD9pUMdUmApc'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = 'price_1PsCmFEha01YHD9pPbZrt36n'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = 'price_1PsCpGEha01YHD9pnE3YcosF'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = 'price_1PsCqsEha01YHD9pnQuGE1Tq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = 'price_1PsCsXEha01YHD9pzW1Gb5xU'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = 'price_1PsCuPEha01YHD9pEru3TLFq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = 'price_1PsCwxEha01YHD9pDNZ7x3VD'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = 'price_1PsCyeEha01YHD9pbKFWi2nt'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = 'price_1PsCzxEha01YHD9p3946nYA4'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = 'price_1PsD0wEha01YHD9pPJJwruw6'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = 'price_1PsD2rEha01YHD9pMIcdlvUf'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = 'price_1PsD5CEha01YHD9pnSjH6221'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = 'price_1PsD7hEha01YHD9pPN9VReGi'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = 'price_1PsDCHEha01YHD9pKKKXG09o'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = 'price_1PsDDbEha01YHD9p1PQREKr7'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = 'price_1PsDEKEha01YHD9ped00r5WZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = 'price_1PsDFQEha01YHD9psGgej5B8'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = 'price_1PsDGkEha01YHD9poqhAhxFW'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = 'price_1PsDHzEha01YHD9pDW4vS1wZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = 'price_1PsDIyEha01YHD9pMiJM1LvQ'
          }
        }
      }
      var filterPrice = this.arrayLicenses.filter(ele => ele.priceId === priceId)
      var newPrice = filterPrice.length ? filterPrice[0].price : 0

      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = '$' + (newPrice ? newPrice : 100)
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = '$' + (newPrice ? newPrice : 200)
        }

        else if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + (newPrice ? newPrice : 19)
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + (newPrice ? newPrice : 29)
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + (newPrice ? newPrice : 49)
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = '$' + (newPrice ? newPrice : 69)
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = '$' + (newPrice ? newPrice : 99)
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = '$' + (newPrice ? newPrice : 139)
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = '$' + (newPrice ? newPrice : 179)
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = '$' + (newPrice ? newPrice : 219)
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = '$' + (newPrice ? newPrice : 259)
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = '$' + (newPrice ? newPrice : 299)
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = '$' + (newPrice ? newPrice : 339)
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = '$' + (newPrice ? newPrice : 379)
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = '$' + (newPrice ? newPrice : 419)
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = '$' + (newPrice ? newPrice : 459)
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = '$' + (newPrice ? newPrice : 499)
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = '$' + (newPrice ? newPrice : 539)
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = '$' + (newPrice ? newPrice : 579)
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = '$' + (newPrice ? newPrice : 619)
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = '$' + (newPrice ? newPrice : 659)
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = '$' + (newPrice ? newPrice : 699)
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = '$' + (newPrice ? newPrice : 739)
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = '$' + (newPrice ? newPrice : 779)
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = '$' + (newPrice ? newPrice : 819)
          }
        }
      } else {
        // monthly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = '$' + (newPrice ? newPrice : 100)
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = '$' + (newPrice ? newPrice : 200)
        }

        else if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + (newPrice ? newPrice : 25)
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + (newPrice ? newPrice : 39)
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + (newPrice ? newPrice : 65)
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = '$' + (newPrice ? newPrice : 89)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = '$' + (newPrice ? newPrice : 129)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = '$' + (newPrice ? newPrice : 179)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = '$' + (newPrice ? newPrice : 229)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = '$' + (newPrice ? newPrice : 279)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = '$' + (newPrice ? newPrice : 329)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = '$' + (newPrice ? newPrice : 379)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = '$' + (newPrice ? newPrice : 429)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = '$' + (newPrice ? newPrice : 479)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = '$' + (newPrice ? newPrice : 529)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = '$' + (newPrice ? newPrice : 579)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = '$' + (newPrice ? newPrice : 629)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = '$' + (newPrice ? newPrice : 679)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = '$' + (newPrice ? newPrice : 729)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = '$' + (newPrice ? newPrice : 779)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = '$' + (newPrice ? newPrice : 829)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = '$' + (newPrice ? newPrice : 879)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = '$' + (newPrice ? newPrice : 929)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = '$' + (newPrice ? newPrice : 979)
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = '$' + (newPrice ? newPrice : 999)
          }
        }
      }

      return priceId
    },
    getActualAmountInteger(typeCrawl) {
      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = 100*100
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = 200*100
        }

        else if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = (19*12)*100
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = (29*12)*100
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = (49*12)*100
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = (69*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = (99*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = (139*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = (179*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = (219*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = (259*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = (299*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = (339*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = (379*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = (419*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = (459*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = (499*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = (539*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = (579*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = (619*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = (659*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = (699*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = (739*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = (779*12)*100
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = (819*12)*100
          }
        }
      } else {
        // monthly
        if(typeCrawl === 5 || typeCrawl === '5') {
          priceId = 100*100
        } else if(typeCrawl === 6 || typeCrawl === '6') {
          priceId = 200*100
        }

        else if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = 2500
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = 3900
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = 6500
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = 8900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = 12900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = 17900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = 22900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = 27900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = 32900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = 37900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = 42900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = 47900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = 52900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = 57900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = 62900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = 67900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = 72900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = 77900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = 82900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = 87900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = 92900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = 97900
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = 99900
          }
        }
      }

      return priceId
    },
    getPlanAmountInteger(typeCrawl, pricePlan) {
      var priceId = ''
      if(this.idsite.paidRecurrency && this.idsite.paidRecurrency === 'Anually') {
        this.monthFree = true
        this.month5 = true
        this.month10 = true
        this.month25 = true
        this.month50 = true
        this.month100 = true
        this.month150 = true
        this.month200 = true
        this.month250 = true
        this.month300 = true
        this.month350 = true
        this.month400 = true
        this.month450 = true
        this.month500 = true
        this.month550 = true
        this.month600 = true
        this.month650 = true
        this.month700 = true
        this.month750 = true
        this.month800 = true
        this.month850 = true
        this.month900 = true
        this.month950 = true
        this.month1000 = true

        // yearly
        if(typeCrawl) {
          this.yearFree = true
        }

        if(typeCrawl === 5) {
          priceId = 228
        } else if(typeCrawl === 10) {
          this.year5 = true
          priceId = 348
        } else if(typeCrawl === 25) {
          this.year5 = true
          this.year10 = true
          priceId = 588
        } else if(typeCrawl === 50) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 828
        } else if(typeCrawl === 100) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 1188
        } else if(typeCrawl === 150) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          priceId = 1668
        } else if(typeCrawl === 200) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          priceId = 2148
        } else if(typeCrawl === 250) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          priceId = 2628
        } else if(typeCrawl === 300) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          priceId = 3108
        } else if(typeCrawl === 350) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          priceId = 3588
        } else if(typeCrawl === 400) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          priceId = 4068
        } else if(typeCrawl === 450) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          priceId = 4548
        } else if(typeCrawl === 500) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          priceId = 5028
        } else if(typeCrawl === 550) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          priceId = 5508
        } else if(typeCrawl === 600) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          priceId = 5988
        } else if(typeCrawl === 650) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          priceId = 6468
        } else if(typeCrawl === 700) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          priceId = 6948
        } else if(typeCrawl === 750) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          priceId = 7428
        } else if(typeCrawl === 800) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          priceId = 7908
        } else if(typeCrawl === 850) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          this.year800 = true
          priceId = 8388
        } else if(typeCrawl === 900) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          this.year800 = true
          this.year850 = true
          priceId = 8868
        } else if(typeCrawl === 950) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          this.year800 = true
          this.year850 = true
          this.year900 = true
          priceId = 9348
        } else if(typeCrawl === 1000) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          this.year100 = true
          this.year150 = true
          this.year200 = true
          this.year250 = true
          this.year300 = true
          this.year350 = true
          this.year400 = true
          this.year450 = true
          this.year500 = true
          this.year550 = true
          this.year600 = true
          this.year650 = true
          this.year700 = true
          this.year750 = true
          this.year800 = true
          this.year850 = true
          this.year900 = true
          this.year950 = true
          priceId = 9828
        }
        // this.year1000 = true
        // }
      } else {
        // monthly
        if(typeCrawl) {
          this.yearFree = true
        }

        if(typeCrawl === 5) {
          priceId = 25
        } else if(typeCrawl === 10) {
          priceId = 39
        } else if(typeCrawl === 25) {
          priceId = 65
        } else if(typeCrawl === 50) {
          priceId = 89
        } else if(typeCrawl === 100) {
          priceId = 129
        } else if(typeCrawl === 150) {
          priceId = 179
        } else if(typeCrawl === 200) {
          this.year5 = true
          priceId = 229
        } else if(typeCrawl === 250) {
          this.year5 = true
          priceId = 279
        } else if(typeCrawl === 300) {
          this.year5 = true
          priceId = 329
        } else if(typeCrawl === 350) {
          this.year5 = true
          this.year10 = true
          priceId = 379
        } else if(typeCrawl === 400) {
          this.year5 = true
          this.year10 = true
          priceId = 429
        } else if(typeCrawl === 450) {
          this.year5 = true
          this.year10 = true
          priceId = 479
        } else if(typeCrawl === 500) {
          this.year5 = true
          this.year10 = true
          priceId = 529
        } else if(typeCrawl === 550) {
          this.year5 = true
          this.year10 = true
          priceId = 579
        } else if(typeCrawl === 600) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 629
        } else if(typeCrawl === 650) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 679
        } else if(typeCrawl === 700) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 729
        } else if(typeCrawl === 750) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          priceId = 779
        } else if(typeCrawl === 800) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 829
        } else if(typeCrawl === 850) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 879
        } else if(typeCrawl === 900) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 929
        } else if(typeCrawl === 950) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 979
        } else if(typeCrawl === 1000) {
          this.year5 = true
          this.year10 = true
          this.year25 = true
          this.year50 = true
          priceId = 999
        }
        // }
      }

      // var validationDisableDowngrade = priceId < pricePlan ? true : false
      // return validationDisableDowngrade
    },
    isAbleToDowngrade(crawlType) {
      // console.log(this.getActualAmountInteger(crawlType))
      if(this.annualyBill) {
        if(this.idsite.paidRecurrency === 'Anually') {
          if(this.idsite.freeTrial) {
            return true
          } else if(this.getActualAmountInteger(crawlType) > this.ammountActualYear) {
            // console.log(this.getActualAmountInteger(crawlType) > this.ammountActualYear)
            return true
          } else {
            // real function only high credit
            // return false

            return true
          }
        } else {
          // console.log(crawlType, (this.getActualAmountInteger(crawlType) > this.ammountActualMonth))
          if(this.idsite.freeTrial) {
            return true
          } else if(this.getActualAmountInteger(crawlType) > this.ammountActualMonth) {
            // console.log(this.getActualAmountInteger(crawlType) > this.ammountActualMonth)
            return true
          } else {
            return false
          }
        }
      } else {
        if(this.idsite.paidRecurrency === 'Anually') {
          if(this.idsite.freeTrial) {
            return true
          } else if(this.getActualAmountInteger(crawlType) > this.ammountActualYear) {
            // console.log(this.getActualAmountInteger(crawlType) > this.ammountActualYear)
            return true
          } else {
            return false
          }
        } else {
          if(this.idsite.freeTrial) {
            return true
          } else if(this.getActualAmountInteger(crawlType) > this.ammountActualMonth) {
            // console.log(this.getActualAmountInteger(crawlType) > this.ammountActualMonth)
            return true
          } else {
            return false
          }
        }
      }
      // return true
    },
    upgradePlan() {
      // console.log('test 1')
      this.processSubmit = true
      var selectedPaymentMethodId = this.selectedPaymentMethod.id
      // console.log(selectedPaymentMethodId);
      // this.showUpdatePlan = false
      this.showUpdateCC = false
      this.processSubmitUpgrade = true
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // this.crawlType
      var amount = this.crawlType === 1 || this.crawlType === '1' ? (this.annualyBill ? 123*100 : 5*100) : this.crawlType === 2 || this.crawlType === '2' ? (this.annualyBill ? 140*100 : 8*100) : this.crawlType === 3 || this.crawlType === '3' ? (this.annualyBill ? 195*100 : 10*100) : this.crawlType === 4 || this.crawlType === '4' ? (this.annualyBill ? (this.customPlan + (this.customSlider - 50))*100 : (this.customPlanMonth + ((this.customSlider - 50) / 10))*100) : this.crawlType === 5 || this.crawlType === '5' ? 10000 : this.crawlType === 6 || this.crawlType === '6' ? 20000 : null
      var plan = this.crawlType === 1 || this.crawlType === '1' ? '5 pages' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages' : this.crawlType === 3 || this.crawlType === '3' ? '25 Pages' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages` : this.crawlType === 5 || this.crawlType === '5' ? '5 pages' : this.crawlType === 6 || this.crawlType === '6' ? '200 pages' : null
      var planQ = this.crawlType === 1 || this.crawlType === '1' ? 5 : this.crawlType === 2 || this.crawlType === '2' ? 10 : this.crawlType === 3 || this.crawlType === '3' ? 25 : this.crawlType === 4 || this.crawlType === '4' ? this.customSlider : this.crawlType === 5 || this.crawlType === '5' ? 5 : this.crawlType === 6 || this.crawlType === '6' ? 200 : null

      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(this.crawlType === 5 || this.crawlType === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(this.crawlType === 6 || this.crawlType === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }

        else if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PePQVEha01YHD9prbkraO7a'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = 'price_1PsDnrEha01YHD9p871921P2'
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
          }
        }
      } else {
        // monthly
        if(this.crawlType === 5 || this.crawlType === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(this.crawlType === 6 || this.crawlType === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }
        
        else if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PsYAIEha01YHD9pFMqNcy4D'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PsY9MEha01YHD9pdo4NDKoI'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PsYAmEha01YHD9pstp3srF0'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = 'price_1PsChZEha01YHD9pUMdUmApc'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = 'price_1PsCmFEha01YHD9pPbZrt36n'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = 'price_1PsCpGEha01YHD9pnE3YcosF'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = 'price_1PsCqsEha01YHD9pnQuGE1Tq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = 'price_1PsCsXEha01YHD9pzW1Gb5xU'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = 'price_1PsCuPEha01YHD9pEru3TLFq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = 'price_1PsCwxEha01YHD9pDNZ7x3VD'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = 'price_1PsCyeEha01YHD9pbKFWi2nt'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = 'price_1PsCzxEha01YHD9p3946nYA4'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = 'price_1PsD0wEha01YHD9pPJJwruw6'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = 'price_1PsD2rEha01YHD9pMIcdlvUf'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = 'price_1PsD5CEha01YHD9pnSjH6221'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = 'price_1PsD7hEha01YHD9pPN9VReGi'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = 'price_1PsDCHEha01YHD9pKKKXG09o'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = 'price_1PsDDbEha01YHD9p1PQREKr7'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = 'price_1PsDEKEha01YHD9ped00r5WZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = 'price_1PsDFQEha01YHD9psGgej5B8'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = 'price_1PsDGkEha01YHD9poqhAhxFW'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = 'price_1PsDHzEha01YHD9pDW4vS1wZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = 'price_1PsDIyEha01YHD9pMiJM1LvQ'
          }
        }
      }

      var urlDesc = new URL(this.idsite.domain.includes('http') ? this.idsite.domain : this.idsite.domainFix)
      var descFix = urlDesc.hostname + (urlDesc.pathname !== '/' ? urlDesc.pathname : '')
      var projectid = this.idsite.projectid
      var siteid = this.idsite.id
      var email = this.userData.email
      var interval = priceId === 'price_1R2RrcEha01YHD9phZxV0VsQ' || priceId === 'price_1R2RsSEha01YHD9phvjVLk9b' ? 'day' : this.annualyBill ? 'year' : 'month'
      plan += ` - Site: ${descFix}`

      var oldPagesActual = parseInt(this.idsite.pages, 10)
      var newPagesActual = parseInt(planQ, 10)
      var reasonChange = ''
      if(this.idsite.paidRecurrency === 'Monthly' && this.annualyBill) {
        reasonChange = 'upgrade'
      } else if(newPagesActual < oldPagesActual) {
        reasonChange = 'downgrade'
      } else {
        reasonChange = 'upgrade'
      }

      if(this.idsite.paidRecurrency === 'Monthly' && this.idsite.subscriptionId && this.annualyBill && this.isAbleToDowngrade(this.crawlType)) {
        reasonChange === 'upgrade'
      }
      // console.log(newPagesActual, oldPagesActual, reasonChange)

      if(this.idsite.IDcustomerUser || this.idsite.customerId) {
        let configUpdCustomerName = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/update-name-customer',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
            userName: this.userData.name ? this.userData.name : this.userData.email
          })
        };

        this.axios(configUpdCustomerName)
        .then(() => {
          console.log('update name customer');
        })
        .catch(e => {
          console.log(e.message);
        })
      }

      // console.log(reasonChange,this.idsite.freeTrial);
      if(reasonChange === 'upgrade' && !this.idsite.freeTrial) {
        let configAmountProration = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-amount-due-proration',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid,
            subscriptionId: this.idsite.subscriptionId,
            reasonChange: reasonChange
          })
        };

        this.axios(configAmountProration)
        .then((responseAmountProration) => {
          // console.log(responseAmountProration.data.subscription);
          if(responseAmountProration.data.success === true) {
            var upcomingInvoice = responseAmountProration.data.subscription
            // const amountDue = upcomingInvoice.total; // Monto total a cobrar en centavos
            const amountDue = upcomingInvoice; // Monto total a cobrar en centavos
            // const amountDueInDollars = (amountDue / 100).toFixed(2); // Convertir a dólares
            const amountDueInDollars = (amountDue).toFixed(2); // Convertir a dólares
            // console.log(`Monto prorrateado: ${amountDue / 100} ${upcomingInvoice.currency.toUpperCase()}`);
            // console.log(`El monto a cobrar al hacer el upgrade es: $${amountDueInDollars}`);

            // this.boxTwo = ''
            // const message = 
            // `Upgrade price is calculated from a credit of the unused portion of the current plan ` +
            // `and billing of the new plan for the same period.` + 
            // `\n\n` + 
            // `Subtotal: $${amountDueInDollars}.`

            // const title = this.$createElement('div', [
            //   this.$createElement('p', 'Upgrade price is calculated from a credit of the unused portion of the current plan and billing of the new plan for the same period.'),
            //   this.$createElement('p', [this.$createElement('strong', 'Subtotal: '), `$${amountDueInDollars}.`]),
            // ])

            this.showOpenCheckout = true
            this.infoCheckout = 'Upgrade price is calculated from a credit of the unused portion of the current plan and billing of the new plan for the same period.'
            this.amountCheckout = amountDueInDollars
            this.processSubmitUpgrade = false
          }
        })
        .catch(e => {
          console.log(e.message);
        })
      } else {
        if(this.idsite.freeTrial) {
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/upgrage-plan',
            headers: { 
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({
              priceId,
              domain,
              amount,
              plan,
              siteid,
              projectid,
              email,
              customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
              serviceDescription: `Site: ${descFix}`,
              interval,
              user: this.userData.id ? this.userData.id : this.userData.uid,
              subscriptionId: this.idsite.subscriptionId,
              // reasonChange: reasonChange === 'upgrade' ? 'downgrade-upgrade' : 'downgrade'
              reasonChange: 'downgrade-upgrade-trial'
              // reasonChange: reasonChange
            })
          };

          this.axios(config)
          .then((response) => {
            // console.log(response.data)
            if(response.status === 200) {
              this.updatePros(this.idsite.id, parseInt(planQ, 10))

              var interval = this.annualyBill ? 'year' : 'month'
              // if(reasonChange === 'upgrade') {
                // if((this.isActivePlan(0)) || interval === 'year') {
                // if((this.isActivePlan(0))) {
              db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                plan: this.crawlType === 5 || this.crawlType === '5' ? 'test' : this.crawlType === 6 || this.crawlType === '6' ? 'test' : this.crawlType === '1' || this.crawlType === 1 ? 'basic' : this.crawlType === '2' || this.crawlType === 2 ? 'pro' : this.crawlType === '3' || this.crawlType === 3 ? 'pro' : 'custom',
                planType: this.crawlType === 5 || this.crawlType === '5' ? '5' : this.crawlType === 6 || this.crawlType === '6' ? '200' : this.crawlType === '1' || this.crawlType === 1 ? '5' : this.crawlType === '2' || this.crawlType === 2 ? '10' : this.crawlType === '3' || this.crawlType === 3 ? '25' : this.customSlider.toString(),
                failPay: false,
                // due: Date.now(),
                cancelSub: false,
                customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
                paidRecurrency: this.crawlType === 5 || this.crawlType === '5' ? 'day' : this.crawlType === 6 || this.crawlType === '6' ? 'day' : interval,
                user: this.userData.id ? this.userData.id : this.userData.uid,
                subscriptionId: response.data.subscription.id,
                downgrade_date: '',
                downgrade_plan: ''
              })
              .then(() => {
                setTimeout(() => {
                  localStorage.setItem('changeInSite', true)
                }, 1300)
              })

              setTimeout(() => {
                localStorage.setItem('changeInSite', true)
                this.$refs.sidebar_info_license.hide()
              }, 2000)

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success update plan',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$refs.sidebar_info_license.hide()

              this.$router.push({
                name: 'view-errors',
                query:{
                  lic: 'upd'
                },
                params: {
                  id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error procesing payment, please try again later',
                  icon: 'BellIcon',
                  variant: 'warning',
                },
              })
            }
            // localStorage.setItem('siteLicenseChanges', true)
            setTimeout(() => {
              this.processSubmitUpgrade = false
              this.processSubmit = false
            }, 1500);
          
          })
          .catch(e => {
            console.log(e)
            this.processSubmitUpgrade = false
            this.processSubmit = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error procesing payment, please try again later',
                icon: 'BellIcon',
                variant: 'warning',
              },
            })
          })
        } else {
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/upgrage-plan',
            headers: { 
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({
              priceId,
              domain,
              amount,
              plan,
              siteid,
              projectid,
              email,
              customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
              serviceDescription: `Site: ${descFix}`,
              interval,
              user: this.userData.id ? this.userData.id : this.userData.uid,
              subscriptionId: this.idsite.subscriptionId,
              reasonChange: reasonChange === 'upgrade' ? 'downgrade-upgrade' : 'downgrade'
              // reasonChange: reasonChange
            })
          };

          this.axios(config)
          .then((response) => {
            // console.log(response.data)
            if(response.status === 200) {
              if(reasonChange === 'upgrade') {
                this.updatePros(this.idsite.id, parseInt(planQ, 10))
              }

              var interval = this.annualyBill ? 'year' : 'month'
              if(reasonChange === 'upgrade') {
                // if((this.isActivePlan(0)) || interval === 'year') {
                // if((this.isActivePlan(0))) {
                  db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                    plan: this.crawlType === 5 || this.crawlType === '5' ? 'test' : this.crawlType === 6 || this.crawlType === '6' ? 'test' : this.crawlType === '1' || this.crawlType === 1 ? 'basic' : this.crawlType === '2' || this.crawlType === 2 ? 'pro' : this.crawlType === '3' || this.crawlType === 3 ? 'pro' : 'custom',
                    planType: this.crawlType === 5 || this.crawlType === '5' ? '5' : this.crawlType === 6 || this.crawlType === '6' ? '200' : this.crawlType === '1' || this.crawlType === 1 ? '5' : this.crawlType === '2' || this.crawlType === 2 ? '10' : this.crawlType === '3' || this.crawlType === 3 ? '25' : this.customSlider.toString(),
                    failPay: false,
                    due: Date.now(),
                    cancelSub: false,
                    customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
                    paidRecurrency: this.crawlType === 5 || this.crawlType === '5' ? 'day' : this.crawlType === 6 || this.crawlType === '6' ? 'day' : interval,
                    user: this.userData.id ? this.userData.id : this.userData.uid,
                    subscriptionId: response.data.subscription.id,
                    downgrade_date: '',
                    downgrade_plan: ''
                  })
                  .then(() => {
                    setTimeout(() => {
                      localStorage.setItem('changeInSite', true)
                    }, 2000)
                  })
                // }
              } else {
                db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                  failPay: false,
                  cancelSub: false,
                  customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
                  paidRecurrency: this.crawlType === 5 || this.crawlType === '5' ? 'day' : this.crawlType === 6 || this.crawlType === '6' ? 'day' : interval,
                  user: this.userData.id ? this.userData.id : this.userData.uid,
                  subscriptionId: response.data.subscription.id
                })
                .then(() => {
                  setTimeout(() => {
                    localStorage.setItem('changeInSite', true)
                  }, 2000)
                })
              }

              setTimeout(() => {
                localStorage.setItem('changeInSite', true)
                this.$refs.sidebar_info_license.hide()
              }, 2000)

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success update plan',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })

              this.$router.push({
                name: 'view-errors',
                query:{
                  lic: 'upd'
                },
                params: {
                  id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error procesing payment, please try again later',
                  icon: 'BellIcon',
                  variant: 'warning',
                },
              })
            }
            // localStorage.setItem('siteLicenseChanges', true)
            setTimeout(() => {
              this.processSubmitUpgrade = false
              this.processSubmit = false
            }, 1500);
          
          })
          .catch(e => {
            console.log(e)
            this.processSubmitUpgrade = false
            this.processSubmit = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error procesing payment, please try again later',
                icon: 'BellIcon',
                variant: 'warning',
              },
            })
          })
        }
      }
    },
    upgradePlanConfirmation() {
      // console.log('test')processSubmitUpgrade,showUpdatePlan
      this.processSubmit = true
      var selectedPaymentMethodId = this.selectedPaymentMethod.id
      // console.log(selectedPaymentMethodId);
      // this.showUpdatePlan = false
      this.processSubmitUpgrade = true
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // this.crawlType
      var amount = this.crawlType === 5 || this.crawlType === '5' ? 10000 : this.crawlType === 6 || this.crawlType === '6' ? 20000 : this.crawlType === 1 || this.crawlType === '1' ? (this.annualyBill ? 123*100 : 5*100) : this.crawlType === 2 || this.crawlType === '2' ? (this.annualyBill ? 140*100 : 8*100) : this.crawlType === 3 || this.crawlType === '3' ? (this.annualyBill ? 195*100 : 10*100) : this.crawlType === 4 || this.crawlType === '4' ? (this.annualyBill ? (this.customPlan + (this.customSlider - 50))*100 : (this.customPlanMonth + ((this.customSlider - 50) / 10))*100) : null
      var plan = this.crawlType === 5 || this.crawlType === '5' ? '5 pages' : this.crawlType === 6 || this.crawlType === '6' ? '200 pages' : this.crawlType === 1 || this.crawlType === '1' ? '5 pages' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages' : this.crawlType === 3 || this.crawlType === '3' ? '25 Pages' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages` : null
      var planQ = this.crawlType === 5 || this.crawlType === '5' ? 5 : this.crawlType === 6 || this.crawlType === '6' ? 200 : this.crawlType === 1 || this.crawlType === '1' ? 5 : this.crawlType === 2 || this.crawlType === '2' ? 10 : this.crawlType === 3 || this.crawlType === '3' ? 25 : this.crawlType === 4 || this.crawlType === '4' ? this.customSlider : null

      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(this.crawlType === 5 || this.crawlType === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(this.crawlType === 6 || this.crawlType === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }

        else if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PePQVEha01YHD9prbkraO7a'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = 'price_1PsDnrEha01YHD9p871921P2'
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
          }
        }
      } else {
        // monthly
        if(this.crawlType === 5 || this.crawlType === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(this.crawlType === 6 || this.crawlType === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }

        else if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PsYAIEha01YHD9pFMqNcy4D'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PsY9MEha01YHD9pdo4NDKoI'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PsYAmEha01YHD9pstp3srF0'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = 'price_1PsChZEha01YHD9pUMdUmApc'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = 'price_1PsCmFEha01YHD9pPbZrt36n'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = 'price_1PsCpGEha01YHD9pnE3YcosF'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = 'price_1PsCqsEha01YHD9pnQuGE1Tq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = 'price_1PsCsXEha01YHD9pzW1Gb5xU'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = 'price_1PsCuPEha01YHD9pEru3TLFq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = 'price_1PsCwxEha01YHD9pDNZ7x3VD'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = 'price_1PsCyeEha01YHD9pbKFWi2nt'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = 'price_1PsCzxEha01YHD9p3946nYA4'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = 'price_1PsD0wEha01YHD9pPJJwruw6'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = 'price_1PsD2rEha01YHD9pMIcdlvUf'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = 'price_1PsD5CEha01YHD9pnSjH6221'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = 'price_1PsD7hEha01YHD9pPN9VReGi'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = 'price_1PsDCHEha01YHD9pKKKXG09o'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = 'price_1PsDDbEha01YHD9p1PQREKr7'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = 'price_1PsDEKEha01YHD9ped00r5WZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = 'price_1PsDFQEha01YHD9psGgej5B8'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = 'price_1PsDGkEha01YHD9poqhAhxFW'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = 'price_1PsDHzEha01YHD9pDW4vS1wZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = 'price_1PsDIyEha01YHD9pMiJM1LvQ'
          }
        }
      }

      var urlDesc = new URL(this.idsite.domain.includes('http') ? this.idsite.domain : this.idsite.domainFix)
      var descFix = urlDesc.hostname + (urlDesc.pathname !== '/' ? urlDesc.pathname : '')
      var projectid = this.idsite.projectid
      var siteid = this.idsite.id
      var email = this.userData.email
      var interval = priceId === 'price_1R2RrcEha01YHD9phZxV0VsQ' || priceId === 'price_1R2RsSEha01YHD9phvjVLk9b' ? 'day' : this.annualyBill ? 'year' : 'month'
      plan += ` - Site: ${descFix}`

      var oldPagesActual = parseInt(this.idsite.pages, 10)
      var newPagesActual = parseInt(planQ, 10)
      var reasonChange = ''
      if(this.idsite.paidRecurrency === 'Monthly' && this.annualyBill) {
        reasonChange = 'upgrade'
      } else if(newPagesActual < oldPagesActual) {
        reasonChange = 'downgrade'
      } else {
        reasonChange = 'upgrade'
      }
      
      if(this.idsite.paidRecurrency === 'Monthly' && this.idsite.subscriptionId && this.annualyBill && this.isAbleToDowngrade(this.crawlType)) {
        reasonChange === 'upgrade'
      }
      // console.log(newPagesActual, oldPagesActual, reasonChange)

      if(this.idsite.IDcustomerUser || this.idsite.customerId) {
        let configUpdCustomerName = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/update-name-customer',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
            userName: this.userData.name ? this.userData.name : this.userData.email
          })
        };

        this.axios(configUpdCustomerName)
        .then(() => {
          console.log('update name customer');
        })
        .catch(e => {
          console.log(e.message);
        })
      }

      // console.log(reasonChange,this.idsite.freeTrial);
      if(reasonChange === 'upgrade') {
        let configAmountProration = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-amount-due-proration',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid,
            subscriptionId: this.idsite.subscriptionId,
            reasonChange: reasonChange
          })
        };

        this.axios(configAmountProration)
        .then((responseAmountProration) => {
          // console.log(responseAmountProration.data.subscription);
          if(responseAmountProration.data.success === true) {
            var upcomingInvoice = responseAmountProration.data.subscription
            // const amountDue = upcomingInvoice.total; // Monto total a cobrar en centavos
            const amountDue = upcomingInvoice; // Monto total a cobrar en centavos
            // const amountDueInDollars = (amountDue / 100).toFixed(2); // Convertir a dólares
            const amountDueInDollars = (amountDue).toFixed(2); // Convertir a dólares
            // console.log(`Monto prorrateado: ${amountDue / 100} ${upcomingInvoice.currency.toUpperCase()}`);
            // console.log(`El monto a cobrar al hacer el upgrade es: $${amountDueInDollars}`);

            // this.boxTwo = ''
            // const message = 
            // `Upgrade price is calculated from a credit of the unused portion of the current plan ` +
            // `and billing of the new plan for the same period.` + 
            // `\n\n` + 
            // `Subtotal: $${amountDueInDollars}.`

            // const title = this.$createElement('div', [
            //   this.$createElement('p', 'Upgrade price is calculated from a credit of the unused portion of the current plan and billing of the new plan for the same period.'),
            //   this.$createElement('p', [this.$createElement('strong', 'Subtotal: '), `$${amountDueInDollars}.`]),
            // ])

            // this.$bvModal
            //   .msgBoxConfirm(title, {
            //     title: 'Confirm upgrade',
            //     size: 'sm',
            //     okVariant: 'primary',
            //     okTitle: 'Checkout',
            //     cancelTitle: 'Cancel',
            //     cancelVariant: 'outline-secondary',
            //     hideHeaderClose: false,
            //     centered: true,
            //   })
            //   .then(value => {
            //     // this.boxTwo = value
            //     // console.log(value);
            //     if(value) {
                  let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/upgrage-plan',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                      priceId,
                      domain,
                      amount,
                      plan,
                      siteid,
                      projectid,
                      email,
                      customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
                      serviceDescription: `Site: ${descFix}`,
                      interval,
                      user: this.userData.id ? this.userData.id : this.userData.uid,
                      subscriptionId: this.idsite.subscriptionId,
                      reasonChange: reasonChange,
                      selectedPaymentMethodId: selectedPaymentMethodId
                    })
                  };

                  this.axios(config)
                  .then((response) => {
                    // console.log(response.data)
                    if(response.status === 200) {
                      this.updatePros(this.idsite.id, parseInt(planQ, 10))

                      var interval = this.annualyBill ? 'year' : 'month'
                      if(reasonChange === 'upgrade') {
                        // if((this.isActivePlan(0)) || interval === 'year') {
                        // if((this.isActivePlan(0))) {
                          db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                            plan: this.crawlType === 5 || this.crawlType === '5' ? 'test' : this.crawlType === 6 || this.crawlType === '6' ? 'test' : this.crawlType === '1' || this.crawlType === 1 ? 'basic' : this.crawlType === '2' || this.crawlType === 2 ? 'pro' : this.crawlType === '3' || this.crawlType === 3 ? 'pro' : 'custom',
                            planType: this.crawlType === 5 || this.crawlType === '5' ? '5' : this.crawlType === 6 || this.crawlType === '6' ? '200' : this.crawlType === '1' || this.crawlType === 1 ? '5' : this.crawlType === '2' || this.crawlType === 2 ? '10' : this.crawlType === '3' || this.crawlType === 3 ? '25' : this.customSlider.toString(),
                            failPay: false,
                            due: Date.now(),
                            cancelSub: false,
                            customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
                            paidRecurrency: this.crawlType === 5 || this.crawlType === '5' ? 'day' : this.crawlType === 6 || this.crawlType === '6' ? 'day' : interval,
                            user: this.userData.id ? this.userData.id : this.userData.uid,
                            subscriptionId: response.data.subscription.id,
                            downgrade_date: '',
                            downgrade_plan: ''
                          })
                          .then(() => {
                            setTimeout(() => {
                              localStorage.setItem('changeInSite', true)
                            }, 2000)
                          })
                        // }
                      } else {
                        db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                          failPay: false,
                          cancelSub: false,
                          customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
                          paidRecurrency: this.crawlType === 5 || this.crawlType === '5' ? 'day' : this.crawlType === 6 || this.crawlType === '6' ? 'day' : interval,
                          user: this.userData.id ? this.userData.id : this.userData.uid,
                          subscriptionId: response.data.subscription.id
                        })
                        .then(() => {
                          setTimeout(() => {
                            localStorage.setItem('changeInSite', true)
                          }, 2000)
                        })
                      }

                      setTimeout(() => {
                        localStorage.setItem('changeInSite', true)
                        this.$refs.sidebar_info_license.hide()
                      }, 2000)

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Success update plan',
                          icon: 'BellIcon',
                          variant: 'success',
                        },
                      })

                      this.$router.push({
                        name: 'view-errors',
                        query:{
                          lic: 'upd'
                        },
                        params: {
                          id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
                        },
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error procesing payment, please try again later',
                          icon: 'BellIcon',
                          variant: 'warning',
                        },
                      })
                    }
                    // localStorage.setItem('siteLicenseChanges', true)
                    setTimeout(() => {
                      this.showUpdatePlan = false
                      this.processSubmitUpgrade = false
                      this.processSubmit = false
                    }, 1500);
                    
                  })
                  .catch(e => {
                    console.log(e)
                    this.showUpdatePlan = false
                    this.processSubmitUpgrade = false
                    this.processSubmit = false

                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error procesing payment, please try again later',
                        icon: 'BellIcon',
                        variant: 'warning',
                      },
                    })
                  })
                // } else {
                //   this.$refs.sidebar_info_license.hide()
                // }
              // })
              // .catch((e) => {
              //   console.log(e.message);
              // })
          } else {
            this.showUpdatePlan = false
            this.processSubmitUpgrade = false
          }
        })
        .catch(e => {
          console.log(e.message);
          this.showUpdatePlan = false
          this.processSubmitUpgrade = false
        })
      } else {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/upgrage-plan',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid,
            subscriptionId: this.idsite.subscriptionId,
            reasonChange: reasonChange
          })
        };

        this.axios(config)
        .then((response) => {
          // console.log(response.data)
          if(response.status === 200) {
            if(reasonChange === 'upgrade') {
              this.updatePros(this.idsite.id, parseInt(planQ, 10))
            }

            var interval = this.annualyBill ? 'year' : 'month'
            if(reasonChange === 'upgrade') {
              // if((this.isActivePlan(0)) || interval === 'year') {
              // if((this.isActivePlan(0))) {
                db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                  plan: this.crawlType === 5 || this.crawlType === '5' ? 'test' : this.crawlType === 6 || this.crawlType === '6' ? 'test' : this.crawlType === '1' || this.crawlType === 1 ? 'basic' : this.crawlType === '2' || this.crawlType === 2 ? 'pro' : this.crawlType === '3' || this.crawlType === 3 ? 'pro' : 'custom',
                  planType: this.crawlType === 5 || this.crawlType === '5' ? '5' : this.crawlType === 6 || this.crawlType === '6' ? '200' : this.crawlType === '1' || this.crawlType === 1 ? '5' : this.crawlType === '2' || this.crawlType === 2 ? '10' : this.crawlType === '3' || this.crawlType === 3 ? '25' : this.customSlider.toString(),
                  failPay: false,
                  due: Date.now(),
                  cancelSub: false,
                  customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
                  paidRecurrency: this.crawlType === 5 || this.crawlType === '5' ? 'day' : this.crawlType === 6 || this.crawlType === '6' ? 'day' : interval,
                  user: this.userData.id ? this.userData.id : this.userData.uid,
                  subscriptionId: response.data.subscription.id,
                  downgrade_date: '',
                  downgrade_plan: ''
                })
                .then(() => {
                  setTimeout(() => {
                    localStorage.setItem('changeInSite', true)
                  }, 2000)
                })
              // }
            } else {
              db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
                failPay: false,
                cancelSub: false,
                customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
                paidRecurrency: this.crawlType === 5 || this.crawlType === '5' ? 'day' : this.crawlType === 6 || this.crawlType === '6' ? 'day' : interval,
                user: this.userData.id ? this.userData.id : this.userData.uid,
                subscriptionId: response.data.subscription.id
              })
              .then(() => {
                setTimeout(() => {
                  localStorage.setItem('changeInSite', true)
                }, 2000)
              })
            }

            setTimeout(() => {
              localStorage.setItem('changeInSite', true)
              this.$refs.sidebar_info_license.hide()
            }, 2000)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success update plan',
                icon: 'BellIcon',
                variant: 'success',
              },
            })

            this.$router.push({
              name: 'view-errors',
              query:{
                lic: 'upd'
              },
              params: {
                id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error procesing payment, please try again later',
                icon: 'BellIcon',
                variant: 'warning',
              },
            })
          }
          // localStorage.setItem('siteLicenseChanges', true)
          setTimeout(() => {
            this.processSubmitUpgrade = false
            this.processSubmit = false
          }, 1500);
          
        })
        .catch(e => {
          console.log(e)
          this.processSubmitUpgrade = false
          this.processSubmit = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error procesing payment, please try again later',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        })
      }
    },
    redirectToBilling(customerId) {
      // this.processInvoice = customerId
      // this.processInvoice = idsite
      this.processSubmitUpgrade = true
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // const stripe = await loadStripe('sk_test_51PVKa9Eha01YHD9pNslDNQ1kuqFaEeF0tpDlaoVDEi6tNIRUU0pzEcCkkWovrVDr9rXvbdmJxAD0kLEmAC1GIt4G00uIJjXPQK');
      
      // Aquí llamas a tu backend para obtener el enlace de facturación
      // const response = await fetch('https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ customerId: 'cus_QVUpm1tcsGouMe', domain: domain})
      // });

      // const { url } = await response.json();

      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({customerId: customerId, domain: domain})
      };
  
      this.axios(config)
      .then((response) => {
        // Redirigir al usuario a la URL de facturación de Stripe
        // window.location.href = response
        // console.log(response.data.url);
        var url = response.data.url
        if(url) {
          window.location.href = url

          setTimeout(() => {
            this.processSubmitUpgrade = false
          }, 1500)
        }
      })
      .catch(e => console.log(e.message))

      // // Redirigir al usuario a la URL de facturación de Stripe
      // window.location.href = url;
    },
    goToStripe() {
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)
      // this.crawlType
      var amount = this.crawlType === 5 || this.crawlType === '5' ? 10000 : this.crawlType === 6 || this.crawlType === '6' ? 20000 : this.crawlType === 1 || this.crawlType === '1' ? (this.annualyBill ? 123*100 : 5*100) : this.crawlType === 2 || this.crawlType === '2' ? (this.annualyBill ? 140*100 : 8*100) : this.crawlType === 3 || this.crawlType === '3' ? (this.annualyBill ? 195*100 : 10*100) : this.crawlType === 4 || this.crawlType === '4' ? (this.annualyBill ? (this.customPlan + (this.customSlider - 50))*100 : (this.customPlanMonth + ((this.customSlider - 50) / 10))*100) : null
      var plan = this.crawlType === 5 || this.crawlType === '5' ? '5 pages' : this.crawlType === 6 || this.crawlType === '6' ? '200 pages' : this.crawlType === 1 || this.crawlType === '1' ? '5 pages' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages' : this.crawlType === 3 || this.crawlType === '3' ? '25 Pages' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages` : null

      var priceId = ''
      if(this.annualyBill) {
        // yearly
        if(this.crawlType === 5 || this.crawlType === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(this.crawlType === 6 || this.crawlType === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }

        else if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PbxnfEha01YHD9piIeM5vVS'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PbxmUEha01YHD9pP485nQ7U'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PePQVEha01YHD9prbkraO7a'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlan + (this.customSlider - 50)) === 220) {
            priceId = 'price_1PsDdHEha01YHD9pL55OW9S4'
          } else if((this.customPlan + (this.customSlider - 50)) === 270) {
            priceId = 'price_1PsDiIEha01YHD9p4iEAQG55'
          } else if((this.customPlan + (this.customSlider - 50)) === 320) {
            priceId = 'price_1PsDkTEha01YHD9pB3tBA7DS'
          } else if((this.customPlan + (this.customSlider - 50)) === 370) {
            priceId = 'price_1PsDnrEha01YHD9p871921P2'
          } else if((this.customPlan + (this.customSlider - 50)) === 420) {
            priceId = 'price_1PsDpuEha01YHD9pb9KPgKgS'
          } else if((this.customPlan + (this.customSlider - 50)) === 470) {
            priceId = 'price_1PsDrTEha01YHD9pe6nrSiij'
          } else if((this.customPlan + (this.customSlider - 50)) === 520) {
            priceId = 'price_1PsDsdEha01YHD9pgCfMsgji'
          } else if((this.customPlan + (this.customSlider - 50)) === 570) {
            priceId = 'price_1PsDtwEha01YHD9pPCk5ykJM'
          } else if((this.customPlan + (this.customSlider - 50)) === 620) {
            priceId = 'price_1PsDv0Eha01YHD9pNn5eB4ex'
          } else if((this.customPlan + (this.customSlider - 50)) === 670) {
            priceId = 'price_1PsDwTEha01YHD9pgzDwv3k1'
          } else if((this.customPlan + (this.customSlider - 50)) === 720) {
            priceId = 'price_1PsDxUEha01YHD9pg8Zuy2H5'
          } else if((this.customPlan + (this.customSlider - 50)) === 770) {
            priceId = 'price_1PsDz3Eha01YHD9p8lBWkiTs'
          } else if((this.customPlan + (this.customSlider - 50)) === 820) {
            priceId = 'price_1PsE0CEha01YHD9pJIonhW6i'
          } else if((this.customPlan + (this.customSlider - 50)) === 870) {
            priceId = 'price_1PsE1SEha01YHD9psjuJqVgG'
          } else if((this.customPlan + (this.customSlider - 50)) === 920) {
            priceId = 'price_1PsE3ZEha01YHD9pmH2n1KWg'
          } else if((this.customPlan + (this.customSlider - 50)) === 970) {
            priceId = 'price_1PsE4dEha01YHD9pV4ku6S0R'
          } else if((this.customPlan + (this.customSlider - 50)) === 1020) {
            priceId = 'price_1PsE5yEha01YHD9pA74l2pRb'
          } else if((this.customPlan + (this.customSlider - 50)) === 1070) {
            priceId = 'price_1PsE86Eha01YHD9p0WX3p7DT'
          } else if((this.customPlan + (this.customSlider - 50)) === 1120) {
            priceId = 'price_1PsE9YEha01YHD9pC21sQCvi'
          } else if((this.customPlan + (this.customSlider - 50)) === 1170) {
            priceId = 'price_1PsEAkEha01YHD9pD8bwdiAx'
          }
        }
      } else {
        // monthly
        if(this.crawlType === 5 || this.crawlType === '5') {
          priceId = 'price_1R2RrcEha01YHD9phZxV0VsQ'
        } else if(this.crawlType === 6 || this.crawlType === '6') {
          priceId = 'price_1R2RsSEha01YHD9phvjVLk9b'
        }

        else if(this.crawlType === 1 || this.crawlType === '1') {
          priceId = 'price_1PsYAIEha01YHD9pFMqNcy4D'
        } else if(this.crawlType === 2 || this.crawlType === '2') {
          priceId = 'price_1PsY9MEha01YHD9pdo4NDKoI'
        } else if(this.crawlType === 3 || this.crawlType === '3') {
          priceId = 'price_1PsYAmEha01YHD9pstp3srF0'
        } else if(this.crawlType === 4 || this.crawlType === '4') {
          if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 12) {
            priceId = 'price_1PsChZEha01YHD9pUMdUmApc'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 17) {
            priceId = 'price_1PsCmFEha01YHD9pPbZrt36n'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 22) {
            priceId = 'price_1PsCpGEha01YHD9pnE3YcosF'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 27) {
            priceId = 'price_1PsCqsEha01YHD9pnQuGE1Tq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 32) {
            priceId = 'price_1PsCsXEha01YHD9pzW1Gb5xU'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 37) {
            priceId = 'price_1PsCuPEha01YHD9pEru3TLFq'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 42) {
            priceId = 'price_1PsCwxEha01YHD9pDNZ7x3VD'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 47) {
            priceId = 'price_1PsCyeEha01YHD9pbKFWi2nt'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 52) {
            priceId = 'price_1PsCzxEha01YHD9p3946nYA4'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 57) {
            priceId = 'price_1PsD0wEha01YHD9pPJJwruw6'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 62) {
            priceId = 'price_1PsD2rEha01YHD9pMIcdlvUf'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 67) {
            priceId = 'price_1PsD5CEha01YHD9pnSjH6221'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 72) {
            priceId = 'price_1PsD7hEha01YHD9pPN9VReGi'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 77) {
            priceId = 'price_1PsDCHEha01YHD9pKKKXG09o'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 82) {
            priceId = 'price_1PsDDbEha01YHD9p1PQREKr7'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 87) {
            priceId = 'price_1PsDEKEha01YHD9ped00r5WZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 92) {
            priceId = 'price_1PsDFQEha01YHD9psGgej5B8'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 97) {
            priceId = 'price_1PsDGkEha01YHD9poqhAhxFW'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 102) {
            priceId = 'price_1PsDHzEha01YHD9pDW4vS1wZ'
          } else if((this.customPlanMonth + ((this.customSlider - 50) / 10)) === 107) {
            priceId = 'price_1PsDIyEha01YHD9pMiJM1LvQ'
          }
        }
      }

      var urlDesc = new URL(this.idsite.domain.includes('http') ? this.idsite.domain : this.idsite.domainFix)
      var descFix = urlDesc.hostname + (urlDesc.pathname !== '/' ? urlDesc.pathname : '')
      var projectid = this.idsite.projectid
      var siteid = this.idsite.id
      var email = this.userData.email
      var interval = priceId === 'price_1R2RrcEha01YHD9phZxV0VsQ' || priceId === 'price_1R2RsSEha01YHD9phvjVLk9b' ? 'day' : this.annualyBill ? 'year' : 'month'
      plan += ` - Site: ${descFix}`
      // console.log(plan);

      // console.log(JSON.stringify({
      //     priceId,
      //     domain,
      //     amount,
      //     plan,
      //     siteid,
      //     projectid,
      //     email
      //   }));
      // console.log(this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId);
      
      if(this.iATrial || (priceId === 'price_1R2RrcEha01YHD9phZxV0VsQ' || priceId === 'price_1R2RsSEha01YHD9phvjVLk9b')) {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-checkout-session',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            userName: this.userData.name ? this.userData.name : this.userData.email,
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid,
            iATrial: this.iATrial
          })
        };

        this.axios(config)
        .then((response) => {
          const data = response.data;
          localStorage.setItem('licenseProcess', JSON.stringify(this.idsite))
          // console.log(data.url);
          window.location.href = data.url;
          this.processSubmit = false
          this.$refs.sidebar_info_license.hide()
        })
        .catch(e => {
          console.log(e)
          this.processSubmit = false
          this.$refs.sidebar_info_license.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error procesing payment, please try again later',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        })
      } else {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-checkout-session-trial',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            userName: this.userData.name ? this.userData.name : this.userData.email,
            priceId,
            domain,
            amount,
            plan,
            siteid,
            projectid,
            email,
            customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId,
            serviceDescription: `Site: ${descFix}`,
            interval,
            user: this.userData.id ? this.userData.id : this.userData.uid
          })
        };

        this.axios(config)
        .then((response) => {
          const data = response.data;
          localStorage.setItem('licenseProcess', JSON.stringify(this.idsite))
          // console.log(data.url);
          window.location.href = data.url;
          this.processSubmit = false
          this.$refs.sidebar_info_license.hide()
        })
        .catch(e => {
          console.log(e)
          this.processSubmit = false
          this.$refs.sidebar_info_license.hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error procesing payment, please try again later',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        })
      }
    },
    submitEditar() {
      this.processSubmit = true
      if(this.crawlType === 0 || this.crawlType === '0') {
        this.cancelSubscription()
        db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
          // plan: 'free',
          // planType: '0',
          // due: null
          failPay: false,
          cancelSub: true,
          cancelationReason: 'Downgrade subscription'
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Plan succesfully changed',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        this.$refs.sidebar_info_license.hide()

        // localStorage.setItem('successLicense', JSON.stringify(this.idsite))

        setTimeout(() => {
          this.processSubmit = false
        }, 2000)
      } else {
        // console.log(this.idsite.planType)
        // console.log(this.idsite, (this.idsite.customerId || this.idsite.IDcustomerUser) && this.idsite.subscriptionId && this.idsite.planType)
        // if((this.idsite.customerId || this.idsite.IDcustomerUser) && this.idsite.subscriptionId && this.idsite.planType) {
        if((this.idsite.customerId || this.idsite.IDcustomerUser) && this.idsite.subscriptionId && this.idsite.planType) {
          var planQ = this.crawlType === 1 || this.crawlType === '1' ? 5 : this.crawlType === 2 || this.crawlType === '2' ? 10 : this.crawlType === 3 || this.crawlType === '3' ? 25 : this.crawlType === 4 || this.crawlType === '4' ? this.customSlider : this.crawlType === 5 || this.crawlType === '5' ? 5 : this.crawlType === 6 || this.crawlType === '6' ? 200 : null
          var oldPagesActual = parseInt(this.idsite.pages, 10)
          var newPagesActual = parseInt(planQ, 10)
          var reasonChange = ''
          if(this.idsite.paidRecurrency === 'Monthly' && this.annualyBill) {
            reasonChange = 'upgrade'
          } else if(newPagesActual < oldPagesActual) {
            reasonChange = 'downgrade'
          } else {
            reasonChange = 'upgrade'
          }

          // if(this.idsite.freeTrial || !this.idsite.planType) {
          if(this.idsite.freeTrial) {
            this.upgradePlan()
          } else {
            this.selectedPaymentMethod = ''
            this.optionPaymentMethod = []

            var atomCustomerId = this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId
            let configpayments = {
              method: 'post',
              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/get-payments-methods',
              headers: { 
                'Content-Type': 'application/json'
              },
              data: JSON.stringify({customerId: atomCustomerId})
            };
        
            this.axios(configpayments)
            .then((responsepayments) => {
              // console.log(responsepayments.data.paymentMethods);
              var responsePaymentsArray = responsepayments.data.paymentMethods.data
              var paymentId = responsepayments.data.defaultPaymentMethod
              var atomArrayPayments = []
              var isDefault = false
              var counterDefCard = 0
              // console.log(paymentId,responsepayments.data)
              this.showUpdatePlan = true
              this.selectedPaymentMethod = ''
              this.optionPaymentMethod = []
              var plan = this.crawlType === 1 || this.crawlType === '1' ? '5 pages license' : this.crawlType === 2 || this.crawlType === '2' ? '10 pages license' : this.crawlType === 3 || this.crawlType === '3' ? '25 pages license' : this.crawlType === 4 || this.crawlType === '4' ? `${this.customSlider} pages license` : this.crawlType === 5 || this.crawlType === '5' ? '5 pages license' : this.crawlType === 6 || this.crawlType === '6' ? '200 pages license' : null
              this.planSelectedPayment = 'Upgrade to: '+ plan

              if(responsePaymentsArray.length) {
                responsePaymentsArray.forEach(ele => {
                  if(paymentId) {
                    if(paymentId === ele.id) {
                      isDefault = true
                    } else {
                      isDefault = false
                    }
                  } else {
                    if(counterDefCard === 0) {
                      isDefault = true
                    } else {
                      isDefault = false
                    }
                    counterDefCard += 1
                  }

                  if(isDefault) {
                    this.selectedPaymentMethod = {id: ele.id, label: ele.card.brand.toUpperCase() + ' ending ' + ele.card.last4 + ' expires ' + ele.card.exp_month + '/' + ele.card.exp_year}
                    this.optionPaymentMethod.unshift({id: ele.id, label: ele.card.brand.toUpperCase() + ' ending ' + ele.card.last4 + ' expires ' + ele.card.exp_month + '/' + ele.card.exp_year})
                  } else {
                    this.optionPaymentMethod.push({id: ele.id, label: ele.card.brand.toUpperCase() + ' ending ' + ele.card.last4 + ' expires ' + ele.card.exp_month + '/' + ele.card.exp_year})
                  }
                })
              } else {
                this.goToStripe()
              }
            })
            .catch(e => {
              console.log(e);
            })
          }
        } else {
          this.goToStripe()
        }
      }
    },
    submitCancelation() {
      // console.log('object');
      localStorage.setItem('cancelInfoLicense', JSON.stringify(this.idsite))
      this.$refs.sidebar_info_license.hide()
    },
    reactivateSubscription() {
      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/reactivate-subscription',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({subscriptionId: this.idsite.subscriptionId})
      };
  
      this.axios(config)
      .then((response) => {
        console.log(response.data);
        db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.id).update({
          // plan: 'free',
          // planType: '0',
          // due: null
          failPay: false,
          cancelSub: false,
          downgrade_date: '',
          downgrade_plan: ''
          // cancelationReason: 'Downgrade subscription'
        })
        this.$refs.sidebar_info_license.hide()

        this.$router.push({
          name: 'view-errors',
          query:{
            lic: 'upd'
          },
          params: {
            id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
          },
        })
      })
      .catch(e => {
        console.log(e.message);
      })
    },
    cancelSubscription() {
      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/cancel-subscription',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({subscriptionId: this.idsite.subscriptionId})
      };
  
      this.axios(config)
      .then((response) => {
        console.log(response.data);
        this.$refs.sidebar_info_license.hide()
        this.$router.push({
          name: 'view-errors',
          query:{
            lic: 'upd'
          },
          params: {
            id: this.idsite.id, imgSite: this.idsite.img, name: this.idsite.name, url: this.idsite.domainFixed, project: this.idsite.projectid, own: 'me'
          },
        })
      })
      .catch(e => {
        console.log(e.message);
      })
    },
    async handleSubmitCC() {
      // console.log('trigger 1',this.cardElement);
      this.processSubmitUpgrade = true
      // Crear un token o PaymentMethod con los datos de la tarjeta
      setTimeout(() => {
        // console.log(paymentMethod.id);
        // if(!paymentMethod.id) {
          this.processSubmitUpgrade = false
        // }
      }, 2500);
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      });

      if(error) {
        // console.log('object1');
        console.error(error);
        // this.showUpdateCC = false
        this.processSubmitUpgrade = false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.code === 'validation_error' ? 'Incorrect data' : error.code === 'invalid_expiry_year_past' ? 'The card expiration year is wrong' : error.code === 'invalid_number' ? 'Your card number is not valid' : error.code === 'incomplete_number' ? 'Your card number is incomplete' : error.code === 'incomplete_zip' ? 'Your zip code is incomplete' : 'Incorrect data',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      } else {
        // console.log('object2');
        // Enviar el paymentMethod.id a tu backend
        this.savePaymentMethod(paymentMethod.id);
        // this.processSubmitUpgrade = true
      }
    },
    async savePaymentMethod(paymentMethodId) {
      // console.log('trigger 2');
      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/save-payment-method',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ paymentMethodId: paymentMethodId, customerId: this.idsite.IDcustomerUser ? this.idsite.IDcustomerUser : this.idsite.customerId })
      };
  
      this.axios(config)
      .then((response) => {
        // console.log(response.data, response.data.status);
        if(response.status === 200) {
          this.submitEditar()
          setTimeout(() => {
            this.processSubmitUpgrade = false
            this.showUpdateCC = false
            // console.log(JSON.stringify(response))
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Payment method added successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }, 2000)
        } else {
          // console.log(e.message);
          this.processSubmitUpgrade = false
          this.showUpdateCC = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to add payment method',
              icon: 'BellIcon',
              variant: 'warning',
            },
          })
        }
      })
      .catch(e => {
        console.log(e.message);
        this.processSubmitUpgrade = false
        this.showUpdateCC = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to add payment method',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      })
    },
    updatePros(val, planType) {
      const user = auth.currentUser
      db.collection('Sites').doc(val).get()
      .then(site => {
        var siteProPages = site.data().proPages && site.data().proPages.length ? site.data().proPages : []

        db.collection('Sites_cache').doc(site.id).get()
        .then(docSiteCache => {
          if(docSiteCache.exists) {
            var hProsOwners = docSiteCache.data().hProsOwners && docSiteCache.data().hProsOwners.length ? docSiteCache.data().hProsOwners : []
            var getHProsOwners = hProsOwners.filter(ele => ele.uid === user.uid)
            var propagesSitesCacheAtom = []
            propagesSitesCacheAtom = [site.data().homepage]

            var atomArrayProUpd = getHProsOwners
            atomArrayProUpd.forEach(ele => {
              if(ele && ele.pros) {
                ele.pros.forEach(elePro => {
                  var filter = propagesSitesCacheAtom.filter(item => item === elePro)
                  if(filter.length === 0) {
                    propagesSitesCacheAtom.push(elePro)
                  }
                })
              }
            })

            var propagesSitesCache = propagesSitesCacheAtom && propagesSitesCacheAtom.length ? propagesSitesCacheAtom : []
            propagesSitesCache = propagesSitesCache.slice(0,parseInt(planType,10))

            var getHProsNoOwners = hProsOwners.filter(ele => ele.uid !== user.uid)
            getHProsNoOwners.push({
              uid: user.uid,
              pros: propagesSitesCache
            })
            var objHCache = getHProsNoOwners

            var ownerUID = site.data().owner && site.data().owner.uid ? site.data().owner.uid : ''
            if(ownerUID === user.uid) {
              db.collection('Sites').doc(site.id).update({
                proPages: propagesSitesCache
              })
              .then(() => {
                console.log('upd pros site')
              })
            }
            
            db.collection('Sites_cache').doc(site.id).update({
              hProsOwners: objHCache
            })
            .then(() => {
              console.log('upd pros cache')
              localStorage.setItem('pro', 'chage_downgrade_license')
              this.sendJSONFix('no_fix', 1, site.id, site.data().homepage, propagesSitesCache)
            })
          }
        })
      })
    },
    sendJSONFix(val, idc, siteId, siteSelected, hProPages) {
      if(idc !== 7) {
        const user = auth.currentUser
        if(user) {
            db.collection('Users').doc(user.uid).get()
            .then(userDoc => {
                setTimeout(() => {
                    db.collection('Sites').doc(siteId).get()
                    .then(siteDoc => {
                      // var proPages = siteDoc.data().proPages && siteDoc.data().proPages.length ? siteDoc.data().proPages : []
                      var proPages = hProPages
                      dbErrors.collection('fixes_cache').where('SiteID', '==', siteId).limit(1).get()
                      .then(docPageFixCacheCollection => {
                        if(docPageFixCacheCollection.size) {
                          docPageFixCacheCollection.forEach(docFixCache => {
                              var urlTransform = new URL(siteSelected)
                              var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                              const getFirstUser = userDoc.id.slice(0, 4);
                              const getFirstSite = siteId.slice(0, 4);
                              // const getFirstProject = this.$route.params.project.slice(0, 3);
                              var keyID = getFirstUser + getFirstSite

                              var obj = {}
                              obj.author = userDoc.data().email
                              obj.css = ""
                              obj.domain = domainConverter
                              obj.key = keyID
                              obj.mode = 1

                              var arraychanges = docFixCache.data().changes && docFixCache.data().changes.length ? docFixCache.data().changes : []
                              var pagesAlreadyProcessed = []
                              arraychanges.forEach(ele => {
                                  if(pagesAlreadyProcessed.filter(item => item === ele.url).length === 0) {
                                      var urlTransform = new URL(ele.url)
                                      // var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                                      pagesAlreadyProcessed.push(ele.url)
                                      var pathURLConverter = urlTransform.pathname === '/' && !ele.url.endsWith('/') ? ele.url + '/' : ele.url
                                      var encodedURL = btoa(pathURLConverter)
                                      obj[encodedURL] = {
                                          "title": "",
                                          "path": urlTransform.pathname,
                                          "url": ele.url,
                                          "description": "",
                                          "canonical": "",
                                          "siteName": "",
                                          "alt": [],
                                          "src": [],
                                          "dlinks": [],
                                          "gp": [],
                                          "tags": {},
                                          "tgstxt": {},
                                          "findAndReplace": []
                                      }
                                      var filterFixesOnlyEachPage = []
                                      // var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                                      var filterFixesOnlyEachPageWDL = arraychanges.filter(item => item.url === ele.url && item.idError === 9)
                                      var filterFixesOnlyEachPageNDL = arraychanges.filter(item => item.url === ele.url && item.idError !== 9)
                                      var condicionParaOrdenarList = (pageA, pageB) => {
                                          return pageB.idfix - pageA.idfix
                                      }
                                      filterFixesOnlyEachPageWDL.sort(condicionParaOrdenarList)
                                      // console.log(ele.url, this.oldText, filterFixesOnlyEachPageWDL)
                                      filterFixesOnlyEachPageWDL.forEach(eleErr => {
                                          if(filterFixesOnlyEachPage.filter(ele => ele.id === eleErr.id || ele.old === eleErr.old).length === 0) {
                                              filterFixesOnlyEachPage.push(eleErr)
                                          }
                                      })

                                      filterFixesOnlyEachPageNDL.forEach(eleErr => {
                                          filterFixesOnlyEachPage.push(eleErr)
                                      })

                                      // var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                                      filterFixesOnlyEachPage.forEach(item => {
                                          if(item.idError === 1) {
                                              // title
                                              obj[encodedURL].title = item.fix
                                              // obj[encodedURL].siteName = item.fix
                                          } else if(item.idError === 2) {
                                              // desc
                                              obj[encodedURL].description = item.fix
                                          } else if(item.idError === 4) {
                                              // h1
                                              if(obj[encodedURL].tgstxt.h1 && obj[encodedURL].tgstxt.h1.length) {
                                              obj[encodedURL].tgstxt.h1.push({
                                                  txt: item.old,
                                                  ntx: item.fix
                                              })
                                              } else {
                                              obj[encodedURL].tgstxt.h1 = [{
                                                  txt: item.old,
                                                  ntx: item.fix
                                              }]
                                              }
                                          } else if(item.idError === 5) {
                                              // h2
                                              if(obj[encodedURL].tgstxt.h2 && obj[encodedURL].tgstxt.h2.length) {
                                              obj[encodedURL].tgstxt.h2.push({
                                                  txt: item.old,
                                                  ntx: item.fix
                                              })
                                              } else {
                                              obj[encodedURL].tgstxt.h2 = [{
                                                  txt: item.old,
                                                  ntx: item.fix
                                              }]
                                              }
                                          } else if(item.idError === 8) {
                                              // imgs
                                              if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                              obj[encodedURL].src.push({
                                                  url: item.old,
                                                  src: item.fix
                                              })
                                              } else {
                                              obj[encodedURL].src = [{
                                                  url: item.old,
                                                  src: item.fix
                                              }]
                                              }

                                              if(item.alt) {
                                              if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                  obj[encodedURL].alt.push({
                                                  url: item.fix,
                                                  alt: item.alt
                                                  })
                                              } else {
                                                  obj[encodedURL].alt = [{
                                                  url: item.fix,
                                                  alt: item.alt
                                                  }]
                                              }
                                              }
                                          } else if(item.idError === 6) {
                                              // lWord
                                              // 08-03-2024 no available fix/edit (word count is value from crawl page)
                                          } else if(item.idError === 13) {
                                              // strings
                                              // if(item.findAndReplace) {
                                              if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                                  obj[encodedURL].findAndReplace.push({
                                                  old: item.old,
                                                  new: item.fix
                                                  })
                                              } else {
                                                  obj[encodedURL].findAndReplace = [{
                                                  old: item.old,
                                                  new: item.fix
                                                  }]
                                              }
                                              // }
                                          } else if(item.idError === 3) {
                                              // mAlt
                                              // if(item.alt) {
                                              if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                  obj[encodedURL].alt.push({
                                                  url: item.urlImg,
                                                  alt: item.fix
                                                  })
                                              } else {
                                                  obj[encodedURL].alt = [{
                                                  url: item.urlImg,
                                                  alt: item.fix
                                                  }]
                                              }
                                              // }
                                          } else if(item.idError === 9) {
                                              // dLink
                                              // if(item.dlinks) {
                                              if(obj[encodedURL].dlinks && obj[encodedURL].dlinks.length) {
                                                  obj[encodedURL].dlinks.push({
                                                  url: item.old,
                                                  rmp: item.fix
                                                  })
                                              } else {
                                                  obj[encodedURL].dlinks = [{
                                                  url: item.old,
                                                  rmp: item.fix
                                                  }]
                                              }
                                              // }
                                          } else if(item.idError === 14) {
                                              // missimgs
                                              if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                              obj[encodedURL].src.push({
                                                  url: item.old,
                                                  src: item.fix
                                              })
                                              } else {
                                              obj[encodedURL].src = [{
                                                  url: item.old,
                                                  src: item.fix
                                              }]
                                              }

                                              if(item.alt) {
                                              if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                                  obj[encodedURL].alt.push({
                                                  url: item.fix,
                                                  alt: item.alt
                                                  })
                                              } else {
                                                  obj[encodedURL].alt = [{
                                                  url: item.fix,
                                                  alt: item.alt
                                                  }]
                                              }
                                              }
                                          } else if(item.idError === 11) {
                                              // urlCan
                                              obj[encodedURL].canonical = item.fix
                                          } else if(item.idError === 10) {
                                              // greenPadlockErrors
                                          } else if(item.idError === 15) {
                                              // Suspicious
                                              // if(item.findAndReplace) {
                                              if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                                  obj[encodedURL].findAndReplace.push({
                                                  old: item.old,
                                                  new: item.fix
                                                  })
                                              } else {
                                                  obj[encodedURL].findAndReplace = [{
                                                  old: item.old,
                                                  new: item.fix
                                                  }]
                                              }
                                              // }
                                          }
                                      })
                                  }
                              })

                              proPages.forEach(ele => {
                                  if(pagesAlreadyProcessed.filter(item => item === ele).length === 0) {
                                      var urlTransform = new URL(ele)

                                      pagesAlreadyProcessed.push(ele)
                                      var pathURLConverter = urlTransform.pathname === '/' && !ele.endsWith('/') ? ele + '/' : ele
                                      var encodedURL = btoa(pathURLConverter)
                                      obj[encodedURL] = {
                                          "title": "",
                                          "path": urlTransform.pathname,
                                          "url": ele,
                                          "description": "",
                                          "canonical": "",
                                          "siteName": "",
                                          "alt": [],
                                          "src": [],
                                          "dlinks": [],
                                          "gp": [],
                                          "tags": {},
                                          "tgstxt": {},
                                          "findAndReplace": [
                                              {
                                                  "old": "seocloudtextfixer",
                                                  "new": "SEOCloud text fixer"
                                              }
                                          ]
                                      }
                                  }
                              })

                              setTimeout(() => {
                                  // console.log(obj);
                                  var config = {
                                      method: 'POST',
                                      url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                                      headers: {
                                          'Content-Type': 'application/json',
                                      },
                                      data: JSON.stringify(obj),
                                  }

                                  this.axios(config)
                                  .then(response => {
                                      dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                                          api_file_request: obj
                                      })
                                      
                                      console.log('fixes file updated: '+response.data.status)
                                  })
                                  .catch(e => console.log(e.message))

                                  var objWP = {
                                      changes: [],
                                      redirects: []
                                  }

                                  Object.entries(obj).forEach(([key, value]) => {
                                    // console.log(`Clave: ${key}, Valor:`, value.a);
                                    if(key !== 'author' && key !== 'css' && key !== 'domain' && key !== 'key' && key !== 'mode') {
                                          var objErrPageWP = {}
                                          objErrPageWP.url = value.url
                                          objErrPageWP.description = value.description
                                          objErrPageWP.canonical = value.canonical
                                          objErrPageWP.title = value.title
                                          objErrPageWP.search_and_replace = []
                                          objErrPageWP.images_src_replace = []
                                          objErrPageWP.images_alt_replace = []
                                          objErrPageWP.links_replace = []

                                          if(value.tgstxt.h1 && value.tgstxt.h1.length) {
                                              value.tgstxt.h1.forEach(ele => {
                                                  objErrPageWP.search_and_replace.push({
                                                      'tag': 'h1',
                                                      'target_text': ele.txt,
                                                      'replace_text': ele.ntx
                                                  })
                                              })
                                          }

                                          if(value.tgstxt.h2 && value.tgstxt.h2.length) {
                                              value.tgstxt.h2.forEach(ele => {
                                                  objErrPageWP.search_and_replace.push({
                                                      'tag': 'h2',
                                                      'target_text': ele.txt,
                                                      'replace_text': ele.ntx
                                                  })
                                              })
                                          }

                                          if(value.src && value.src.length) {
                                              value.src.forEach(ele => {
                                                  objErrPageWP.images_src_replace.push({
                                                      'target_src': ele.url,
                                                      'replace_src': ele.src
                                                  })
                                              })
                                          }

                                          if(value.alt && value.alt.length) {
                                              value.alt.forEach(ele => {
                                                  objErrPageWP.images_alt_replace.push({
                                                      'target_src': ele.url,
                                                      'replace_alt': ele.alt
                                                  })
                                              })
                                          }

                                          if(value.dlinks && value.dlinks.length) {
                                              value.dlinks.forEach(ele => {
                                                  objErrPageWP.links_replace.push({
                                                      'target_url': ele.url,
                                                      'replace_url': ele.rmp
                                                  })
                                              })
                                          }

                                          if(value.findAndReplace && value.findAndReplace.length) {
                                              value.findAndReplace.forEach(ele => {
                                                  objErrPageWP.search_and_replace.push({
                                                      'tag': 'p',
                                                      'target_text': ele.old,
                                                      'replace_text': ele.new
                                                  })
                                              })
                                          }
                                          objWP.changes.push(objErrPageWP)
                                      }
                                  });
                                  var configWP = {
                                      method: 'POST',
                                      // url: `${domainConverter}/wp-admin/admin-ajax.php?action=seocloud_autosync_changes`,
                                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/json-wp',
                                      headers: {
                                          'Content-Type': 'application/json',
                                          // 'User-Agent': 'insomnia/10.1.1',
                                          // 'seocloud-registration-key': keyID
                                      },
                                      data: JSON.stringify({data: objWP, key: keyID, domain: domainConverter}),
                                  }

                                  this.axios(configWP)
                                  .then(() => {
                                      console.log('fixes file updated wp')
                                  })
                                  .catch(e => console.log(e.message))
                              }, 4000)
                          })
                        } else {
                          var urlTransform = new URL(siteSelected)
                          var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                          const getFirstUser = userDoc.id.slice(0, 4);
                          const getFirstSite = siteId.slice(0, 4);
                          // const getFirstProject = this.$route.params.project.slice(0, 3);
                          var keyID = getFirstUser + getFirstSite

                          var obj = {}
                          obj.author = userDoc.data().email
                          obj.css = ""
                          obj.domain = domainConverter
                          obj.key = keyID
                          obj.mode = 1

                          var pagesAlreadyProcessed = []
                          proPages.forEach(ele => {
                            if(pagesAlreadyProcessed.filter(item => item === ele).length === 0) {
                                var urlTransform = new URL(ele)

                                pagesAlreadyProcessed.push(ele)
                                var pathURLConverter = urlTransform.pathname === '/' && !ele.endsWith('/') ? ele + '/' : ele
                                var encodedURL = btoa(pathURLConverter)
                                obj[encodedURL] = {
                                    "title": "",
                                    "path": urlTransform.pathname,
                                    "url": ele,
                                    "description": "",
                                    "canonical": "",
                                    "siteName": "",
                                    "alt": [],
                                    "src": [],
                                    "dlinks": [],
                                    "gp": [],
                                    "tags": {},
                                    "tgstxt": {},
                                    "findAndReplace": [
                                        {
                                            "old": "seocloudtextfixer",
                                            "new": "SEOCloud text fixer"
                                        }
                                    ]
                                }
                            }
                          })

                          setTimeout(() => {
                            // console.log(obj);
                            var config = {
                              method: 'POST',
                              url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                              headers: {
                                  'Content-Type': 'application/json',
                              },
                              data: JSON.stringify(obj),
                            }

                            this.axios(config)
                            .then(response => {
                              // dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                              //   api_file_request: obj
                              // })
                              
                              console.log('fixes file updated: '+response.data.status)
                            })
                            .catch(e => console.log(e.message))

                            var objWP = {
                              changes: [],
                              redirects: []
                            }

                            Object.entries(obj).forEach(([key, value]) => {
                              // console.log(`Clave: ${key}, Valor:`, value.a);
                              if(key !== 'author' && key !== 'css' && key !== 'domain' && key !== 'key' && key !== 'mode') {
                                    var objErrPageWP = {}
                                    objErrPageWP.url = value.url
                                    objErrPageWP.description = value.description
                                    objErrPageWP.canonical = value.canonical
                                    objErrPageWP.title = value.title
                                    objErrPageWP.search_and_replace = []
                                    objErrPageWP.images_src_replace = []
                                    objErrPageWP.images_alt_replace = []
                                    objErrPageWP.links_replace = []

                                    if(value.tgstxt.h1 && value.tgstxt.h1.length) {
                                        value.tgstxt.h1.forEach(ele => {
                                            objErrPageWP.search_and_replace.push({
                                                'tag': 'h1',
                                                'target_text': ele.txt,
                                                'replace_text': ele.ntx
                                            })
                                        })
                                    }

                                    if(value.tgstxt.h2 && value.tgstxt.h2.length) {
                                        value.tgstxt.h2.forEach(ele => {
                                            objErrPageWP.search_and_replace.push({
                                                'tag': 'h2',
                                                'target_text': ele.txt,
                                                'replace_text': ele.ntx
                                            })
                                        })
                                    }

                                    if(value.src && value.src.length) {
                                        value.src.forEach(ele => {
                                            objErrPageWP.images_src_replace.push({
                                                'target_src': ele.url,
                                                'replace_src': ele.src
                                            })
                                        })
                                    }

                                    if(value.alt && value.alt.length) {
                                        value.alt.forEach(ele => {
                                            objErrPageWP.images_alt_replace.push({
                                                'target_src': ele.url,
                                                'replace_alt': ele.alt
                                            })
                                        })
                                    }

                                    if(value.dlinks && value.dlinks.length) {
                                        value.dlinks.forEach(ele => {
                                            objErrPageWP.links_replace.push({
                                                'target_url': ele.url,
                                                'replace_url': ele.rmp
                                            })
                                        })
                                    }

                                    if(value.findAndReplace && value.findAndReplace.length) {
                                        value.findAndReplace.forEach(ele => {
                                            objErrPageWP.search_and_replace.push({
                                                'tag': 'p',
                                                'target_text': ele.old,
                                                'replace_text': ele.new
                                            })
                                        })
                                    }
                                    objWP.changes.push(objErrPageWP)
                                }
                            });
                            var configWP = {
                              method: 'POST',
                              // url: `${domainConverter}/wp-admin/admin-ajax.php?action=seocloud_autosync_changes`,
                              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/json-wp',
                              headers: {
                                'Content-Type': 'application/json',
                                // 'User-Agent': 'insomnia/10.1.1',
                                // 'seocloud-registration-key': keyID
                              },
                              data: JSON.stringify({data: objWP, key: keyID, domain: domainConverter}),
                            }

                            this.axios(configWP)
                            .then(() => {
                              console.log('fixes file updated wp')
                            })
                            .catch(e => console.log(e.message))
                          }, 4000)
                        }
                      })
                    })
                }, 2000)
            })
        }
      }
    }
  },
  mounted() {
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
/* .modal-open {
  z-index: 9999999 !important;
} */
#mi-modal___BV_modal_outer_ {
  z-index: 9999999 !important;
}
#mi-modal {
  z-index: 9999999 !important;
}
.modal-custom-info-license {
  z-index: 9999999 !important;
}
.modal-custom-info-license1 {
  z-index: 9999999 !important;
}
#card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
input[type="radio"]:checked {
  background-color: #7838FF;
  padding: 4px;
  border: 5px solid #7838FF;
}
input[type=radio]{
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #DCDCF2;
  background-color: #F0F1F7;
}
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.slider-custom-plan .vue-slider-process {
  background-color: #7838ff;
}
.slider-custom-plan .vue-slider-dot-tooltip-inner {
  background-color: #7838ff;
  border-color: #7838ff;
}
.update-plan-confirm:hover {
  border: 1px solid #7838FF !important;
  opacity: 0.8;
}
.modal-open .modal.fade.show {
  background: #000000a1 !important;
  z-index: 99999;
}
@media (max-width: 768px) {
  .text-card-desc-license {
    width: 50%;
    line-height: 1;
  }
  .container-all-cards {
    height: 650px !important;
  }
  .card-config-license {
    max-height: 87px !important;
    height: 87px !important;
  }
  .custom-radio-plan-container {
    max-height: 87px !important;
    height: 87px !important;
  }
  .card-config-license2 {
    max-height: 155px !important;
    height: 155px !important;
  }
  .custom-radio-plan-container2 {
    max-height: 155px !important;
    height: 155px !important;
  }
  .desc-license-custom {
    margin-left: 30% !important;
  }
}
@media (max-width: 361px) {
  .desc-license-custom {
    margin-left: 20% !important;
  }
}
</style>

<style scoped>
/* ::v-deep(.modal) {
  z-index: 9999999 !important;
}
::v-deep(.modal-backdrop) {
  z-index: 9999998 !important;
} */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-incomplete-crawl {
  width: 90%;
  max-width: 593px !important;
}
</style>